import { useState, ReactNode } from "react";

import { tCargo } from "../../store/types/shipping.types";
import SelectCargo from "../../components/SelectCargo/SelectCargo";

type tTriggerFunction = React.Dispatch<
  React.SetStateAction<tCargo | null>
> | null;

const useSelectCargo = (): [ReactNode, (func: tTriggerFunction) => void] => {
  const [triggerFunction, setTriggerFunction] =
    useState<tTriggerFunction>(null);

  const defaultTriggerFunction = (cargo: tCargo) => null;

  const updateTriggerFunction = (func: tTriggerFunction) => {
    setTriggerFunction(() => func);
  };

  const closeHandler = () => {
    setTriggerFunction(null);
  };

  const render = (
    <SelectCargo
      selectHandler={triggerFunction || defaultTriggerFunction}
      open={!!triggerFunction}
      closeHandler={closeHandler}
    />
  );

  return [render, updateTriggerFunction];
};

export default useSelectCargo;
