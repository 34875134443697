import { useState, ReactNode } from "react";

import { tContact } from "../../store/types/shipping.types";
import SelectContact from "../../components/SelectContact/SelectContact";

type tTriggerFunction = React.Dispatch<
  React.SetStateAction<tContact | null>
> | null;

export type tSelectContactData = { user?: string; state?: string };

const useSelectContact = (
  options: tSelectContactData = {}
): [ReactNode, (func: tTriggerFunction) => void] => {
  const [triggerFunction, setTriggerFunction] =
    useState<tTriggerFunction>(null);

  const defaultTriggerFunction = (contact: tContact) => null;

  const updateTriggerFunction = (func: tTriggerFunction) => {
    setTriggerFunction(() => func);
  };

  const closeHandler = () => {
    setTriggerFunction(null);
  };

  const render = (
    <SelectContact
      selectHandler={triggerFunction || defaultTriggerFunction}
      open={!!triggerFunction}
      closeHandler={closeHandler}
      user={options.user}
      state={options.state}
    />
  );

  return [render, updateTriggerFunction];
};

export default useSelectContact;
