export const MONTHS_SHORT = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export const DATE_RANGES = [
  { key: "TODAY", value: "Today" },
  { key: "YESTERDAY", value: "Yesterday" },
  { key: "7_DAYS", value: "7 days" },
  { key: "30_DAYS", value: "30 days" },
  { key: "60_DAYS", value: "60 days" },
  { key: "90_DAYS", value: "90 days" },
  { key: "CUSTOM", value: "Custom" },
];

export const SHIPMENTS_STATUS = {
  TRANSIT: {
    ADDRESS_ISSUE:
      "Address information is incorrect. Contact carrier to ensure delivery",
    CONTACT_CARRIER: "Contact the carrier for more information",
    DELAYED: "Package delivery is delayed",
    DELIVERY_ATTEMPTED:
      "Delivery of package has been attempted. Contact carrier to ensure delivery",
    DELIVERY_RESCHEDULED: "Delivery of package has been rescheduled",
    DELIVERY_SCHEDULED: "Package is scheduled for delivery",
    LOCATION_INACCESSIBLE:
      "Delivery location inaccessible to carrier. Contact carrier to ensure delivery",
    NOTICE_LEFT:
      "Carrier left notice during attempted delivery. Follow carrier instructions on notice",
    OUT_FOR_DELIVERY: "Package is out for delivery",
    PACKAGE_ACCEPTED:
      "Package has been accepted into the carrier network for delivery",
    PACKAGE_ARRIVED:
      "Package has arrived at an intermediate location in the carrier network",
    PACKAGE_DAMAGED:
      "Package has been damaged. Contact carrier for more details",
    PACKAGE_DEPARTED:
      "Package had departed from an intermediate location in the carrier network",
    PACKAGE_FORWARDED: "Package has been forwarded",
    PACKAGE_HELD:
      "Package held at carrier location. Contact carrier for more details",
    PACKAGE_PROCESSED: "Package has been processed at an intermediate location",
    PACKAGE_PROCESSING:
      "Package is processing at an intermediate location in the carrier network",
    PICKUP_AVAILABLE: "Package is available for pickup at carrier location",
    RESCHEDULE_DELIVERY: "Contact carrier to reschedule delivery",
  },
  DELIVERED: {
    DELIVERED: "Package has been delivered",
  },
  FAILED: {
    PACKAGE_UNDELIVERABLE: "Package is not able to be delivered",
    PACKAGE_DISPOSED: "Package has been disposed",
    PACKAGE_LOST: "Package has been lost. Contact carrier for more details.",
  },
  RETURNED: {
    RETURN_TO_SENDER: "Package is to be returned to sender",
    PACKAGE_UNCLAIMED: "Package is unclaimed",
  },
};

export const ALL_SHIPMENTS_STATUS = [
  "CREATED",
  "PRE-TRANSIT",
  "TRANSIT",
  "FAILED",
  "DELIVERED",
  "RETURNED",
  "UNKNOWN",
  "CANCELED",
];
