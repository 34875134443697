import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tCountries } from "../../store/types/app.types";
import {
  updateBranches,
  updateBusinessSectors,
  updateCarriers,
  updateCountries,
  updateCurrencies,
  updateExportReasons,
  updateImportantCharges,
  updateInvoiceTypes,
  updateOverview,
  updateShippingTerms,
  updateTransportTypes,
} from "../../store/cacheReducer";

import { SelectBoxDataType } from "../../components/SelectBox/SelectBox";

const useData = () => {
  const dispatch = useDispatch();

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );

  // Unhandled error

  // No unhandled error
  const fetchInvoiceTypes = useCallback(() => {
    return new Promise((resolve, reject) => {
      api_client({
        method: "GET",
        url: "/invoice-types",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          dispatch(updateInvoiceTypes(res.data.data));

          resolve("Invoice types fetched successfully");
        })
        .catch((err) => {
          resolve("Error fetching invoice types");
        });
    });
  }, [accessToken, dispatch]);

  const fetchShippingTerms = useCallback(() => {
    return new Promise((resolve, reject) => {
      api_client({
        method: "GET",
        url: "/shipping-terms",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          dispatch(updateShippingTerms(res.data.data));

          resolve("Shipping terms fetched successfully");
        })
        .catch((err) => {
          resolve("Error fetching shipping terms");
        });
    });
  }, [accessToken, dispatch]);

  const fetchExportReasons = useCallback(() => {
    return new Promise((resolve, reject) => {
      api_client({
        method: "GET",
        url: "/export-reasons",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          dispatch(updateExportReasons(res.data.data));

          resolve("Export reasons fetched successfully");
        })
        .catch((err) => {
          resolve("Error fetching export reasons");
        });
    });
  }, [accessToken, dispatch]);

  const fetchCurrencies = useCallback(() => {
    return new Promise((resolve, reject) => {
      api_client({
        method: "GET",
        url: "/currencies",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          dispatch(updateCurrencies(res.data.data));

          resolve("Currencies fetched successfully");
        })
        .catch((err) => {
          resolve("Error fetching Currencies");
        });
    });
  }, [accessToken, dispatch]);

  const fetchCountries = useCallback(() => {
    return new Promise((resolve, reject) => {
      api_client({
        method: "GET",
        url: "/countries",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          const countries: tCountries = res.data.data;

          const SelectCountriesData: SelectBoxDataType = [];

          for (const country of countries) {
            SelectCountriesData.push({
              key: country._id,
              value: {
                img: country.FlagImgPath,
                text: `${country.Name} (${country.TelephoneCode})`,
              },
            });
          }

          dispatch(
            updateCountries({
              countries,
              countriesSelectData: SelectCountriesData,
            })
          );

          resolve("Currencies fetched successfully");
        })
        .catch((err) => {
          resolve("Error fetching Currencies");
        });
    });
  }, [accessToken, dispatch]);

  const fetchImportantCharges = useCallback(() => {
    return new Promise((resolve, reject) => {
      api_client({
        method: "GET",
        url: "/charges/DLF",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          dispatch(updateImportantCharges({ key: "DLF", data: res.data.data }));

          return api_client({
            method: "GET",
            url: "/charges/FRC",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
        })
        .then((res) => {
          dispatch(updateImportantCharges({ key: "FRC", data: res.data.data }));

          resolve("Important charges fetched successfully");
        })
        .catch((err) => {
          resolve("Error fetching important charges");
        });
    });
  }, [accessToken, dispatch]);

  const fetchCarriers = useCallback(() => {
    return new Promise((resolve, reject) => {
      api_client({
        method: "GET",
        url: "/carriers?no_pagination=true",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          dispatch(updateCarriers(res.data.data));

          resolve("Carriers fetched successfully");
        })
        .catch((err) => {
          resolve("Error fetching carriers");
        });
    });
  }, [accessToken, dispatch]);

  const fetchBranches = useCallback(() => {
    return new Promise((resolve, reject) => {
      api_client({
        method: "GET",
        url: "/branches?no_pagination=true",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          dispatch(updateBranches(res.data.data));

          resolve("Branches fetched successfully");
        })
        .catch((err) => {
          resolve("Error fetching branches");
        });
    });
  }, [accessToken, dispatch]);

  const fetchBusinessSectors = useCallback(() => {
    return new Promise((resolve, reject) => {
      api_client({
        method: "GET",
        url: "/users/business-sectors",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          dispatch(updateBusinessSectors(res.data.data));

          resolve("Business sectors fetched successfully");
        })
        .catch((err) => {
          resolve("Error fetching business sectors");
        });
    });
  }, [accessToken, dispatch]);

  const fetchOverview = useCallback(() => {
    return new Promise((resolve, reject) => {
      api_client({
        method: "GET",
        url: `/shipments/overview`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          dispatch(updateOverview(res.data.data));

          resolve(res.data.data);
        })
        .catch((err) => {
          resolve("Error fetching Overview");
        });
    });
  }, [accessToken, dispatch]);

  const fetchTransportTypes = useCallback(() => {
    return new Promise((resolve, reject) => {
      api_client({
        method: "GET",
        url: "/riders/transport-types",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          dispatch(updateTransportTypes(res.data.data));

          resolve("Transport types fetched successfully");
        })
        .catch((err) => {
          resolve("Error fetching transport types");
        });
    });
  }, [accessToken, dispatch]);

  return {
    fetchInvoiceTypes,
    fetchShippingTerms,
    fetchExportReasons,
    fetchCurrencies,
    fetchCountries,
    fetchImportantCharges,
    fetchCarriers,
    fetchBranches,
    fetchOverview,
    fetchBusinessSectors,
    fetchTransportTypes,
  };
};

export default useData;
