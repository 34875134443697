import { IonIcon } from "@ionic/react";
import { alertCircle, checkmarkCircle, close } from "ionicons/icons";

export type AlertTypes = "success" | "error" | "warning";
export type AlertContentType = string | TrustedHTML;

type AlertType = {
  children: AlertContentType;
  type: AlertTypes;
  closeHandler: () => void;
};

const Alert = ({ children, type, closeHandler }: AlertType) => {
  return (
    <div className={`alert alert--${type}`}>
      <div className="alert__icon-box">
        <IonIcon
          icon={
            type === "success"
              ? checkmarkCircle
              : type === "warning"
              ? alertCircle
              : close
          }
        />
      </div>
      <div
        className="alert__message"
        dangerouslySetInnerHTML={{ __html: children }}
      ></div>
      <div className="alert__close-block">
        <span className="alert__close" onClick={closeHandler}>
          <IonIcon icon={close} />
        </span>
      </div>
    </div>
  );
};

export default Alert;
