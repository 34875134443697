import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import cls from "classnames";

import { IonIcon } from "@ionic/react";
import {
  checkmarkOutline,
  searchOutline,
  createOutline,
  trashOutline,
  addOutline,
  chevronDownOutline,
  closeOutline,
  alertCircle,
  arrowUpOutline,
} from "ionicons/icons";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import {
  tCargo,
  tCarrier,
  tCharge,
  tChargeType,
  tContact,
  tExportReason,
  tInvoiceType,
  tPackagingType,
  tShipmentCargo,
  tShipmentCargos,
  tShipmentCharge,
  tShipmentCharges,
  tShipmentType,
  tShippingTerm,
} from "../../store/types/shipping.types";

import { getInputDate, isNumber } from "../../utils/func";
import {
  tBranch,
  tCountry,
  tCurrency,
  tUser,
} from "../../store/types/app.types";

import withAuth from "../../hoc/withAuth/withAuth";

import useData from "../../hooks/useData/useData";
import useSelectContact from "../../hooks/useSelectContact/useSelectContact";
import useSelectBox from "../../hooks/useSelectBox/useSelectBox";
import useSelectCargo from "../../hooks/useSelectCargo/useSelectCargo";
import useSelectPackagingType from "../../hooks/useSelectPackagingType/useSelectPackagingType";
import useSelectCharge from "../../hooks/useSelectCharge/useSelectCharge";
import useSelectCarrier from "../../hooks/useSelectCarrier/useSelectCarrier";
import useSelectBranch from "../../hooks/useSelectBranch/useSelectBranch";

import Header from "../../components/Header/Header";
import SearchBox from "../../components/SearchBox/SearchBox";
import Spinner from "../../Loaders/Spinner/Spinner";
import useSelectUser from "../../hooks/useSelectUser/useSelectUser";
import VerticalBarLoader from "../../Loaders/VerticalBarLoader/VerticalBarLoader";

export function compSelectHandler<T extends { _id: string }>(
  arr: T[],
  setState: React.Dispatch<React.SetStateAction<T | null>>
): (e: ChangeEvent<HTMLSelectElement>) => void {
  return (e: ChangeEvent<HTMLSelectElement>) =>
    setState(arr.find((dt) => dt._id === e.target.value) || null);
}

declare global {
  interface Window {
    google: any;
  }
}

const NewShipping = () => {
  const navigate = useNavigate();

  const { id: shipmentId } = useParams();

  const { accessToken, admin } = useSelector((state: tRootState) => state.user);

  const adminBranch = admin?.Branch;

  const {
    shippingTypes,
    invoiceTypes,
    shippingTerms,
    exportReasons,
    currencies,
    countries,
    countriesSelectData,
    chargeTypes,
    importantCharges,
  } = useSelector((state: tRootState) => state.cache);

  const {
    fetchInvoiceTypes,
    fetchShippingTerms,
    fetchExportReasons,
    fetchCurrencies,
    fetchCountries,
    fetchImportantCharges,
  } = useData();

  const [selectContact, openSelectContact] = useSelectContact();
  const [selectCarrier, openSelectCarrier] = useSelectCarrier();
  const [selectCargo, openSelectCargo] = useSelectCargo();
  const [selectPackagingType, openSelectPackagingType] =
    useSelectPackagingType();
  const [selectCharge, openSelectCharge] = useSelectCharge();
  const [selectBranch, openSelectBranch] = useSelectBranch();
  const [selectUser, openSelectUser] = useSelectUser();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  // loading
  const [loading, setLoading] = useState(!!shipmentId);

  // Metadata
  const [metadataIssues, setMetadataIssues] = useState<string[]>([]);
  const [metadataValid, setMetadataValid] = useState(false);

  const [branch, setBranch] = useState<tBranch | null>(adminBranch || null);

  const [branchSearch, setBranchSearch] = useState(adminBranch?.Name || "");
  const [branchSearchFocus, setBranchSearchFocus] = useState(false);

  const [user, setUser] = useState<tUser | null>(null);

  const [userSearch, setUserSearch] = useState("");
  const [userSearchFocus, setUserSearchFocus] = useState(false);

  // Basic Information
  const [basicInfoIssues, setBasicInfoIssues] = useState<string[]>([]);
  const [basicInfoValid, setBasicInfoValid] = useState(false);

  const [shipmentDescription, setShipmentDescription] = useState("");
  const [shipmentType, setShipmentType] = useState<tShipmentType | "">("");
  const [invoiceType, setInvoiceType] = useState<tInvoiceType | null>(null);
  const [shippingTerm, setShippingTerm] = useState<tShippingTerm | null>(null);
  const [declaredValue, setDeclaredValue] = useState<number | string>("");
  const [declaredValueCurrency, setDeclaredValueCurrency] =
    useState<tCurrency | null>(null);
  const [cargoInsurance, setCargoInsurance] = useState<number | string>("");
  const [exportReason, setExportReason] = useState<tExportReason | null>(null);

  // Origin Information
  const [originIssues, setOriginIssues] = useState<string[]>([]);
  const [originValid, setOriginValid] = useState(false);

  const [selectedOrigin, setSelectedOrigin] = useState<tContact | null>(null);

  const [originNameFocus, setOriginNameFocus] = useState(false);
  const [originName, setOriginName] = useState("");

  const [originCompany, setOriginCompany] = useState("");

  const originAddressRef = useRef<HTMLInputElement | null>(null);
  const [originAddress, setOriginAddress] = useState("");

  const [originAddress2, setOriginAddress2] = useState("");
  const [originAddress3, setOriginAddress3] = useState("");
  const [originSuburb, setOriginSuburb] = useState("");
  const [originCity, setOriginCity] = useState("");
  const [originPostalCode, setOriginPostalCode] = useState("");
  const [originState, setOriginState] = useState("");
  const [originCountry, setOriginCountry] = useState<tCountry | null>(null);
  const [originEmailAddress, setOriginEmailAddress] = useState("");
  const [
    originTelephoneCountrySelectBox,
    selectedOriginTelephoneCountryId,
    openOriginTelephoneCountrySelectBox,
  ] = useSelectBox("Select Country", countriesSelectData);
  const [originTelephoneCountry, setOriginTelephoneCountry] =
    useState<tCountry | null>(null);
  const [originTelephone, setOriginTelephone] = useState<number | "">("");
  const [
    originTelephone2CountrySelectBox,
    selectedOriginTelephone2CountryId,
    openOriginTelephone2CountrySelectBox,
  ] = useSelectBox("Select Country", countriesSelectData);
  const [originTelephone2Country, setOriginTelephone2Country] =
    useState<tCountry | null>(null);
  const [originTelephone2, setOriginTelephone2] = useState<number | "">("");
  const [originIsResidential, setOriginIsResidential] = useState(false);
  const [originSaveContact, setOriginSaveContact] = useState(false);

  const [pickupDate, setPickupDate] = useState("");

  // Destination Information
  const [destinationIssues, setDestinationIssues] = useState<string[]>([]);
  const [destinationValid, setDestinationValid] = useState(false);

  const [selectedDestination, setSelectedDestination] =
    useState<tContact | null>(null);

  const [destinationNameFocus, setDestinationNameFocus] = useState(false);
  const [destinationName, setDestinationName] = useState("");

  const [destinationCompany, setDestinationCompany] = useState("");

  const destinationAddressRef = useRef<HTMLInputElement | null>(null);
  const [destinationAddress, setDestinationAddress] = useState("");

  const [destinationAddress2, setDestinationAddress2] = useState("");
  const [destinationAddress3, setDestinationAddress3] = useState("");
  const [destinationSuburb, setDestinationSuburb] = useState("");
  const [destinationCity, setDestinationCity] = useState("");
  const [destinationPostalCode, setDestinationPostalCode] = useState("");
  const [destinationState, setDestinationState] = useState("");
  const [destinationCountry, setDestinationCountry] = useState<tCountry | null>(
    null
  );
  const [destinationEmailAddress, setDestinationEmailAddress] = useState("");
  const [
    destinationTelephoneCountrySelectBox,
    selectedDestinationTelephoneCountryId,
    openDestinationTelephoneCountrySelectBox,
  ] = useSelectBox("Select Country", countriesSelectData);
  const [destinationTelephoneCountry, setDestinationTelephoneCountry] =
    useState<tCountry | null>(null);
  const [destinationTelephone, setDestinationTelephone] = useState<number | "">(
    ""
  );
  const [
    destinationTelephone2CountrySelectBox,
    selectedDestinationTelephone2CountryId,
    openDestinationTelephone2CountrySelectBox,
  ] = useSelectBox("Select Country", countriesSelectData);
  const [destinationTelephone2Country, setDestinationTelephone2Country] =
    useState<tCountry | null>(null);
  const [destinationTelephone2, setDestinationTelephone2] = useState<
    number | ""
  >("");
  const [destinationIsResidential, setDestinationIsResidential] =
    useState(false);
  const [destinationSaveContact, setDestinationSaveContact] = useState(false);

  const [deliveryDate, setDeliveryDate] = useState("");

  // Cargo Details
  const [cargoIssues1, setCargoIssues1] = useState<string[]>([]);
  const [cargoIssues1Valid, setCargoIssues1Valid] = useState(false);
  const [cargoIssues2, setCargoIssues2] = useState<string[]>([]);
  const [cargoIssues2Valid, setCargoIssues2Valid] = useState(false);

  const [shipmentCargoIndex, setShipmentCargoIndex] = useState<number | null>(
    null
  );
  const [shipmentCargos, setShipmentCargos] = useState<tShipmentCargos>([]);

  const [selectedCargo, setSelectedCargo] = useState<tCargo | null>(null);

  const [cargoDescriptionFocus, setCargoDescriptionFocus] = useState(false);
  const [cargoDescription, setCargoDescription] = useState("");

  const [cargoItemCode, setCargoItemCode] = useState("");

  const [cargoQuantity, setCargoQuantity] = useState<number | "">("");
  const [cargoDeclaredValue, setCargoDeclaredValue] = useState<number | string>(
    ""
  );

  const [cargoPackagingType, setCargoPackagingType] =
    useState<tPackagingType | null>(null);

  const [cargoWeight, setCargoWeight] = useState<number | string>("");
  const [cargoWeightMeasure, setCargoWeightMeasure] = useState("KG");
  const [cargoLength, setCargoLength] = useState<number | string>("");
  const [cargoBreadth, setCargoBreadth] = useState<number | string>("");
  const [cargoHeight, setCargoHeight] = useState<number | string>("");

  // Parties
  const [partiesIssues, setPartiesIssues] = useState<string[]>([]);
  const [partiesValid, setPartiesValid] = useState(false);

  const [carrierSearch, setCarrierSearch] = useState("");
  const [carrierSearchFocus, setCarrierSearchFocus] = useState(false);

  const [carrier, setCarrier] = useState<tCarrier | null>(null);

  // Charges
  const [chargesIssues1, setChargesIssues1] = useState<string[]>([]);
  const [chargesIssues1Valid, setChargesIssues1Valid] = useState(false);
  const [chargesIssues2, setChargesIssues2] = useState<string[]>([]);
  const [chargesIssues2Valid, setChargesIssues2Valid] = useState(false);

  const [shipmentChargeIndex, setShipmentChargeIndex] = useState<number | null>(
    null
  );
  const [shipmentCharges, setShipmentCharges] = useState<tShipmentCharges>([]);

  const [chargeChargeSearch, setChargeChargeSearch] = useState("");
  const [chargeChargeSearchFocus, setChargeChargeSearchFocus] = useState(false);

  const [chargeCharge, setChargeCharge] = useState<tCharge | null>(null);

  const [chargeChargeType, setChargeChargeType] = useState<tChargeType | "">(
    ""
  );

  const [chargePayerType, setChargePayerType] = useState<
    "User" | "Branch" | ""
  >("");

  const [chargePayerSearch, setChargePayerSearch] = useState("");
  const [chargePayerSearchFocus, setChargePayerSearchFocus] = useState(false);

  const [chargePayerUser, setChargePayerUser] = useState<tUser | null>(null);
  const [chargePayerBranch, setChargePayerBranch] = useState<tBranch | null>(
    null
  );

  const [chargeAmount, setChargeAmount] = useState<number | string>("");
  const [chargeCurrency, setChargeCurrency] = useState<tCurrency | null>(null);

  // References
  const [referencesIssues, setReferencesIssues] = useState<string[]>([]);
  const [referencesValid, setReferencesValid] = useState(false);

  const [shipperReferenceNumber, setShipperReferenceNumber] = useState("");
  const [forwarderReferenceNumer, setForwarderReferenceNumber] = useState("");
  const [purchaseOrderNumber, setPurchaseOrderNumber] = useState("");
  const [tariffNumber, setTariffNumber] = useState("");
  const [contractPartyReferenceNumber, setContractPartyReferenceNumber] =
    useState("");
  const [cosigneeReferenceNumber, setCosigneeReferenceNumber] = useState("");
  const [BLReferenceNumber, setBLReferenceNumber] = useState("");

  const [alertMessages, setAlertMessages] = useState<string[]>([]);
  const [rateInfo, setRateInfo] = useState<{
    rate: number;
    pickupDate: string;
    totalWeight: number;
  } | null>(null);

  const [triggerAddressField, setTriggerAddressField] = useState(false);

  const newShipment = useRef("");

  const createShipment = () => {
    setShowConfirmationModal(false);

    if (carrier?.Code === "DHL" && shipmentType === "Local")
      return setAlertMessages([
        "Carrier DHL should not be used for local shipments",
      ]);

    setShowSpinner(true);

    // basic info
    const shipmentData: any = {
      Branch: branch!._id,
      User: user!._id,
      Description: shipmentDescription,
      Type: shipmentType,
      ValueInsuranceCurrency: declaredValueCurrency!._id,
      DeclaredValue: declaredValue,
      PickupDate: pickupDate,
      DeliveryDate: deliveryDate,
    };

    if (["Import", "Export"].includes(shipmentType))
      shipmentData.InvoiceType = invoiceType!._id;
    if (shippingTerm) shipmentData.ShippingTerm = shippingTerm._id;
    if (cargoInsurance) shipmentData.CargoInsurance = cargoInsurance;
    if (exportReason) shipmentData.ExportReason = exportReason._id;

    // origin
    let origin: any;
    if (selectedOrigin && !originChanged()) {
      origin = selectedOrigin._id;
    } else {
      origin = {
        Name: originName,
        Company: originCompany,
        Address: originAddress,
        City: originCity,
        State: originState,
        Country: originCountry!._id,
        TelephoneCountry: originTelephoneCountry!._id,
        Telephone: `${originTelephoneCountry!.TelephoneCode}${originTelephone}`,
        Residential: originIsResidential,
        Saved: originSaveContact,
      };

      if (originAddress2) origin.Address2 = originAddress2;
      if (originAddress3) origin.Address3 = originAddress3;
      if (originSuburb) origin.Suburb = originSuburb;
      if (originPostalCode) origin.PostalCode = originPostalCode;
      if (originEmailAddress) origin.EmailAddress = originEmailAddress;
      if (originTelephone2Country)
        origin.Telephone2Country = originTelephone2Country._id;
      if (originTelephone2) origin.Telephone2 = originTelephone2;
    }

    shipmentData.Shipper = origin;

    // destination
    let destination: any;
    if (selectedDestination && !destinationChanged()) {
      destination = selectedDestination._id;
    } else {
      destination = {
        Name: destinationName,
        Company: destinationCompany,
        Address: destinationAddress,
        City: destinationCity,
        State: destinationState,
        Country: destinationCountry!._id,
        TelephoneCountry: destinationTelephoneCountry!._id,
        Telephone: `${
          destinationTelephoneCountry!.TelephoneCode
        }${destinationTelephone}`,
        Residential: destinationIsResidential,
        Saved: destinationSaveContact,
      };

      if (destinationAddress2) destination.Address2 = destinationAddress2;
      if (destinationAddress3) destination.Address3 = destinationAddress3;
      if (destinationSuburb) destination.Suburb = destinationSuburb;
      if (destinationPostalCode) destination.PostalCode = destinationPostalCode;
      if (destinationEmailAddress)
        destination.EmailAddress = destinationEmailAddress;
      if (destinationTelephone2Country)
        destination.Telephone2Country = destinationTelephone2Country._id;
      if (destinationTelephone2) destination.Telephone2 = destinationTelephone2;
    }

    shipmentData.Cosignee = destination;

    // cargo details
    if (shipmentType === "Local") {
      shipmentData.Cargos = [];
    } else {
      shipmentData.Cargos = shipmentCargos.map((cargo) => {
        const cargoData: any = {
          Description: cargo.cargoDescription,
          Quantity: cargo.cargoQuantity,
          DeclaredValue: cargo.cargoDeclaredValue,
          DeclaredValueCurrency: declaredValueCurrency!._id,
          Weight: cargo.cargoWeight,
          WeightMeasure: cargo.cargoWeightMeasure,
          Length: cargo.cargoLength,
          Breadth: cargo.cargoBreadth,
          Height: cargo.cargoHeight,
        };

        if (cargo.cargoItemCode) cargoData.ItemCode = cargo.cargoItemCode;
        if (cargo.cargoPackagingType)
          cargoData.PackagingType = cargo.cargoPackagingType._id;

        return cargoData;
      });
    }

    //parties
    shipmentData.Carrier = carrier!._id;

    // charges
    shipmentData.Charges = shipmentCharges.map((charge) => {
      const chargeData: any = {
        Charge: charge.chargeCharge._id,
        Type: charge.chargeChargeType,
        PayerOrPayeeType: charge.chargePayerType,
        Amount: charge.chargeAmount,
        Currency: charge.chargeCurrency._id,
      };

      if (charge.chargePayerType === "Branch")
        chargeData.Branch = charge.chargePayerBranch!._id;

      if (charge.chargePayerType === "User")
        chargeData.User = charge.chargePayerUser!._id;

      return chargeData;
    });

    // references
    if (shipperReferenceNumber)
      shipmentData.ShipperReference = shipperReferenceNumber;
    if (forwarderReferenceNumer)
      shipmentData.ForwarderReference = forwarderReferenceNumer;
    if (contractPartyReferenceNumber)
      shipmentData.ContractPartyReference = contractPartyReferenceNumber;
    if (cosigneeReferenceNumber)
      shipmentData.CosigneeReference = cosigneeReferenceNumber;
    if (BLReferenceNumber) shipmentData.BLReference = BLReferenceNumber;
    if (purchaseOrderNumber)
      shipmentData.PurchaseOrderNumber = purchaseOrderNumber;
    if (shipperReferenceNumber)
      shipmentData.TariffNumber = shipperReferenceNumber;

    api_client({
      url: shipmentId ? `/shipments/${shipmentId}` : "/shipments",
      method: shipmentId ? "PUT" : "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(shipmentData),
    })
      .then((res) => {
        newShipment.current = res.data.data._id;
        setShowSuccessModal(true);
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          setAlertMessages([err.response.data.message]);
        } else {
          setAlertMessages(["Error creating shipment. Try again"]);
        }
      })
      .finally(() => {
        setShowSpinner(false);
      });
  };

  const searchRate = () => {
    if (!carrier) return;

    if (!originValid)
      return setAlertMessages([
        "Fill in Origin(Shipper) information correctly",
      ]);
    if (!destinationValid)
      return setAlertMessages([
        "Fill in Destination(Cosignee) information correctly",
      ]);
    if (!cargoIssues1Valid)
      return setAlertMessages(["Fill in Cargo Details information correctly"]);

    setShowSpinner(true);

    api_client({
      method: "POST",
      url: `/shipments/rate/${carrier._id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: JSON.stringify({
        pickupDate: pickupDate,
        shipper: {
          Country: originCountry?._id,
          State: originState,
          City: originCity,
          PostalCode: originPostalCode,
          Address: originAddress,
          Address2: originAddress2,
          Address3: originAddress3,
        },
        receiver: {
          Country: destinationCountry?._id,
          State: destinationState,
          City: destinationCity,
          PostalCode: destinationPostalCode,
          Address: destinationAddress,
          Address2: destinationAddress2,
          Address3: destinationAddress3,
        },
        cargos: shipmentCargos.map((cargo) => ({
          Weight: cargo.cargoWeight,
          Quantity: cargo.cargoQuantity,
          Length: cargo.cargoLength,
          Breadth: cargo.cargoBreadth,
          Height: cargo.cargoHeight,
        })),
      }),
    })
      .then((res) => {
        setRateInfo(res.data.data);
      })
      .catch((err) => {
        if (err.code === "ERR_BAD_REQUEST") {
          setAlertMessages([err.response.data.message]);
        } else {
          setAlertMessages(["Error fetching rate. Try again"]);
        }
      })
      .finally(() => {
        setShowSpinner(false);
      });
  };

  // Charge Methods
  const addUpdateShipmentCharge = () => {
    if (!chargesIssues2Valid) return setAlertMessages(chargesIssues2);

    // if (
    //   shipmentCharges.find(
    //     (charge) => charge.chargeCharge.Code === chargeCharge!.Code
    //   ) &&
    //   typeof shipmentChargeIndex !== "number"
    // )
    //   return setAlertMessages([
    //     "You are not allowed to add the same charge more than once",
    //   ]);

    const newShipmentCharge: tShipmentCharge = {
      chargeCharge: chargeCharge!,
      chargeChargeType: chargeChargeType as tChargeType,
      chargePayerType: chargePayerType! as any, // bad practise (no time)
      chargePayerUser: chargePayerUser,
      chargePayerBranch: chargePayerBranch,
      chargeAmount: +chargeAmount,
      chargeCurrency: chargeCurrency!,
    };

    if (typeof shipmentChargeIndex === "number") {
      setShipmentCharges((shpCharges) =>
        shpCharges.map((shpCharge, i) =>
          i === shipmentChargeIndex ? newShipmentCharge : shpCharge
        )
      );
      setShipmentChargeIndex(null);
    } else {
      setShipmentCharges((shpCharges) => [...shpCharges, newShipmentCharge]);
    }

    if (chargeCharge!.Code === "FRC") {
      const deliveryFeeChargeIndex = shipmentCharges.findIndex(
        (charge) => charge.chargeCharge.Code === "DLF"
      );
      if (deliveryFeeChargeIndex >= 0)
        removeShipmentCharge(deliveryFeeChargeIndex);

      setChargeCharge(importantCharges.DLF);
      setChargeChargeType("INCOME");
      setChargePayerType("");
      setChargeAmount(
        (destinationCountry!.DHLProfitMargin / 100) * +chargeAmount +
          +chargeAmount
      );
      const naira =
        currencies.find((currency) => currency.Code === "NGN") || null;
      setChargeCurrency(naira);
    } else {
      setChargeCharge(null);
      setChargeChargeType("");
      setChargePayerType("");
      setChargeAmount("");
      const naira =
        currencies.find((currency) => currency.Code === "NGN") || null;
      setChargeCurrency(naira);
    }
  };

  const removeShipmentCharge = (index: number) => {
    setShipmentCharges((shpCharges) =>
      shpCharges.filter((shpCharge, i) => i !== index)
    );
  };

  // Cargo Methods
  const addUpdateShipmentCargo = () => {
    if (!cargoIssues2Valid) return setAlertMessages(cargoIssues2);

    const newShipmentCargo: tShipmentCargo = {
      cargoDescription,
      cargoItemCode,
      cargoQuantity: +cargoQuantity,
      cargoDeclaredValue: +cargoDeclaredValue,
      cargoPackagingType,
      cargoWeight: +cargoWeight,
      cargoWeightMeasure,
      cargoLength: +cargoLength || 1,
      cargoBreadth: +cargoBreadth || 1,
      cargoHeight: +cargoHeight || 1,
    };

    if (typeof shipmentCargoIndex === "number") {
      setShipmentCargos((shpCargos) =>
        shpCargos.map((shpCargo, i) =>
          i === shipmentCargoIndex ? newShipmentCargo : shpCargo
        )
      );
      setShipmentCargoIndex(null);
    } else {
      setShipmentCargos((shpCargos) => [...shpCargos, newShipmentCargo]);
    }

    setCargoDescription("");
    setCargoItemCode("");
    setCargoQuantity("");
    setCargoDeclaredValue("");
    setCargoPackagingType(null);
    setCargoWeight("");
    setCargoWeightMeasure("KG");
    setCargoLength("");
    setCargoBreadth("");
    setCargoHeight("");
  };

  const removeShipmentCargo = (index: number) => {
    setShipmentCargos((shpCargos) =>
      shpCargos.filter((shpCargo, i) => i !== index)
    );
  };

  const destinationChanged = () => {
    if (!selectedDestination) return;

    if (selectedDestination.Name !== destinationName) return true;
    if (selectedDestination.Company !== destinationCompany) return true;
    if (selectedDestination.Address !== destinationAddress) return true;
    if ((selectedDestination.Address2 || "") !== destinationAddress2)
      return true;
    if ((selectedDestination.Address3 || "") !== destinationAddress3)
      return true;
    if ((selectedDestination.Suburb || "") !== destinationSuburb) return true;
    if ((selectedDestination.PostalCode || "") !== destinationPostalCode)
      return true;
    if (selectedDestination.Country._id !== destinationCountry?._id)
      return true;
    if (selectedDestination.State !== destinationState) return true;
    if (selectedDestination.City !== destinationCity) return true;
    if (selectedDestination.EmailAddress !== destinationEmailAddress)
      return true;
    if (
      selectedDestination.TelephoneCountry._id !==
      destinationTelephoneCountry?._id
    )
      return true;
    if (
      +selectedDestination.Telephone.slice(
        selectedDestination.Telephone.length - 10
      ) !== destinationTelephone
    )
      return true;

    if (
      selectedDestination.Telephone2Country &&
      selectedDestination.Telephone2
    ) {
      if (
        (selectedDestination.Telephone2Country?._id || null) !==
        destinationTelephone2Country?._id
      )
        return true;
      if (
        (selectedDestination.Telephone2
          ? +selectedDestination.Telephone2.slice(
              selectedDestination.Telephone2.length - 10
            )
          : "") !== destinationTelephone2
      )
        return true;
    }

    return false;
  };

  const originChanged = () => {
    if (!selectedOrigin) return;

    if (selectedOrigin.Name !== originName) return true;
    if (selectedOrigin.Company !== originCompany) return true;
    if (selectedOrigin.Address !== originAddress) return true;
    if ((selectedOrigin.Address2 || "") !== originAddress2) return true;
    if ((selectedOrigin.Address3 || "") !== originAddress3) return true;
    if ((selectedOrigin.Suburb || "") !== originSuburb) return true;
    if ((selectedOrigin.PostalCode || "") !== originPostalCode) return true;
    if (selectedOrigin.Country._id !== originCountry?._id) return true;
    if (selectedOrigin.State !== originState) return true;
    if (selectedOrigin.City !== originCity) return true;
    if (selectedOrigin.EmailAddress !== originEmailAddress) return true;
    if (selectedOrigin.TelephoneCountry._id !== originTelephoneCountry?._id)
      return true;
    if (
      +selectedOrigin.Telephone.slice(selectedOrigin.Telephone.length - 10) !==
      originTelephone
    )
      return true;
    if (selectedOrigin.Telephone2Country && selectedOrigin.Telephone2) {
      if (
        (selectedOrigin.Telephone2Country?._id || null) !==
        originTelephone2Country?._id
      )
        return true;
      if (
        (selectedOrigin.Telephone2
          ? +selectedOrigin.Telephone2.slice(
              selectedOrigin.Telephone2.length - 10
            )
          : "") !== originTelephone2
      )
        return true;
    }

    return false;
  };

  // Validation useEffects

  // Metadata validation
  useEffect(() => {
    const data: string[] = [];

    if (!branch) data.push("Branch is required");
    if (!user) data.push("Customer is required");

    setMetadataIssues(data);
    setMetadataValid(!data.length);
  }, [branch, user]);

  // Basic Information Validation
  useEffect(() => {
    const data: string[] = [];

    if (!shipmentDescription) data.push("Shipment description is required");
    if (shipmentDescription.length > 70)
      data.push("Max length for shipment description is 70 characters"); // DHL Specific
    if (!shipmentType) data.push("Shipment type is required");

    if (
      !shipmentType ||
      (["Import", "Export"].includes(shipmentType) && !invoiceType)
    )
      data.push("Invoice type is required");

    if (!declaredValue) data.push("Declared value is required");
    if (!declaredValueCurrency)
      data.push("Declared value currency is required");

    setBasicInfoIssues(data);
    setBasicInfoValid(!data.length);
  }, [
    shipmentDescription,
    shipmentType,
    invoiceType,
    shippingTerm,
    declaredValue,
    declaredValueCurrency,
    cargoInsurance,
    exportReason,
  ]);

  // Origin Validation
  useEffect(() => {
    const data: string[] = [];

    if (!originName) data.push("Name is required");
    if (!originCompany) data.push("Company is required");
    if (!originAddress) data.push("Address is required");
    if (originAddress.length > 45)
      data.push("Max length for address is 45 characters"); // DHL Specific
    if (!originCity) data.push("City is required");
    if (!originState) data.push("State is required");
    if (!originCountry) data.push("Country is required");
    // if (!originEmailAddress) data.push("Email address is required");
    if (!originTelephoneCountry) data.push("Telephone Country is required");
    if (!originTelephone) data.push("Telephone is required");

    if (!pickupDate) data.push("Pickup date is required");

    if (originCountry && branch && originCountry._id !== branch.Country._id)
      data.push("Origin country must be the same as branch country");

    if (originState && branch && originState !== branch.State)
      data.push("Origin state must be the same as branch state");

    setOriginIssues(data);
    setOriginValid(!data.length);
  }, [
    originName,
    originCompany,
    originAddress,
    originCity,
    originState,
    originCountry,
    originEmailAddress,
    originTelephoneCountry,
    originTelephone,
    selectedOrigin,
    selectedDestination,
    pickupDate,
    branch,
    shipmentType,
  ]);

  // Destination Validation
  useEffect(() => {
    const data: string[] = [];

    if (!destinationName) data.push("Name is required");
    if (!destinationCompany) data.push("Company is required");
    if (!destinationAddress) data.push("Address is required");
    if (destinationAddress.length > 45)
      data.push("Max length for address is 45 characters"); // DHL Specific
    if (!destinationCity) data.push("City is required");
    if (!destinationState) data.push("State is required");
    if (!destinationCountry) data.push("Country is required");
    // if (!destinationEmailAddress) data.push("Email address is required");
    if (!destinationTelephoneCountry)
      data.push("Telephone Country is required");
    if (!destinationTelephone) data.push("Telephone is required");

    if (!deliveryDate) data.push("Delivery date is required");

    // Contact validation
    if (
      selectedOrigin &&
      selectedDestination &&
      selectedOrigin._id === selectedDestination._id
    )
      data.push("Destination contact must not be the same as origin contact");

    // Address validation
    if (
      destinationCountry &&
      ["Local", "Nationwide"].includes(shipmentType) &&
      branch &&
      destinationCountry._id !== branch.Country._id
    )
      data.push("Destination country must be the same as branch country");

    if (
      destinationCountry &&
      ["Local", "Nationwide"].includes(shipmentType) &&
      !branch &&
      originCountry &&
      destinationCountry._id !== originCountry._id
    )
      data.push("Destination country must be the same as origin country");

    if (
      destinationState &&
      shipmentType === "Local" &&
      branch &&
      destinationState !== branch.State
    )
      data.push("Destination state must be the same as branch state");

    if (
      destinationState &&
      shipmentType === "Local" &&
      !branch &&
      originState &&
      destinationState !== originState
    )
      data.push("Destination state must be the same as origin state");

    // if (
    //   destinationState &&
    //   shipmentType === "Nationwide" &&
    //   branch &&
    //   destinationState === branch.State
    // )
    //   data.push("Destination state must not be the same as branch state");

    // Commented for nationwide local
    // if (
    //   destinationState &&
    //   shipmentType === "Nationwide" &&
    //   !branch &&
    //   originState &&
    //   destinationState === originState
    // )
    //   data.push("Destination state must not be the same as origin state");

    if (
      destinationCountry &&
      shipmentType === "Export" &&
      branch &&
      destinationCountry._id === branch.Country._id
    )
      data.push("Destination country cannot be the same as branch country");

    if (
      destinationCountry &&
      shipmentType === "Export" &&
      !branch &&
      originCountry &&
      destinationCountry._id === originCountry._id
    )
      data.push("Destination country cannot be the same as origin country");

    setDestinationIssues(data);
    setDestinationValid(!data.length);
  }, [
    destinationName,
    destinationCompany,
    destinationAddress,
    destinationCity,
    destinationState,
    destinationCountry,
    destinationEmailAddress,
    destinationTelephoneCountry,
    destinationTelephone,
    selectedOrigin,
    selectedDestination,
    deliveryDate,
    branch,
    shipmentType,
    originCountry,
    originState,
  ]);

  // Cargo Validation
  useEffect(() => {
    const data: string[] = [];

    if (!shipmentCargos.length)
      data.push("At least one shipment cargo is required");

    if (shipmentCargos.length) {
      const totalValue = shipmentCargos.reduce(
        (a, b) => a + b.cargoDeclaredValue,
        0
      );

      if (+totalValue !== +declaredValue)
        data.push("Total cargo value does not match shipment declared value");
    }

    setCargoIssues1(data);
    setCargoIssues1Valid(!data.length);
  }, [shipmentCargos, declaredValue]);

  useEffect(() => {
    const data: string[] = [];

    if (!cargoDescription) data.push("Cargo description is required");
    if (!cargoQuantity) data.push("Cargo quantity is required");
    if (!cargoDeclaredValue) data.push("Cargo declared value is required");
    if (!cargoWeight) data.push("Cargo weight is required");

    if (cargoLength && (Number.isNaN(+cargoLength) || +cargoLength < 1))
      data.push("Cargo length must be greater than or equal to 1");
    if (cargoBreadth && (Number.isNaN(+cargoBreadth) || +cargoBreadth < 1))
      data.push("Cargo breadth must be greater than or equal to 1");
    if (cargoHeight && (Number.isNaN(+cargoHeight) || +cargoHeight < 1))
      data.push("Cargo height must be greater than or equal to 1");

    if (shipmentType === "Export" && !cargoItemCode)
      data.push("Cargo item code is required");

    setCargoIssues2(data);
    setCargoIssues2Valid(!data.length);
  }, [
    cargoDescription,
    cargoQuantity,
    cargoDeclaredValue,
    cargoWeight,
    cargoItemCode,
    shipmentType,
    cargoLength,
    cargoBreadth,
    cargoHeight,
  ]);

  // Parties Validation
  useEffect(() => {
    const data: string[] = [];

    if (!carrier) data.push("Carrier is required");

    setPartiesIssues(data);
    setPartiesValid(!data.length);
  }, [carrier]);

  // Charges Validation
  useEffect(() => {
    const data: string[] = [];

    if (!shipmentCharges.find((charge) => charge.chargeChargeType === "INCOME"))
      data.push("An income charge is required");

    if (
      !shipmentCharges.find((charge) => charge.chargeChargeType === "EXPENSE")
    )
      data.push("At lease one expense charge is required");

    setChargesIssues1(data);
    setChargesIssues1Valid(!data.length);
  }, [shipmentCharges]);

  useEffect(() => {
    const data: string[] = [];

    if (!chargeCharge) data.push("Select a valid charge");
    if (!chargeChargeType) data.push("Charge type is required");
    if (!chargePayerType) data.push("Charge payer type is required");
    if (
      (chargePayerType === "User" && !chargePayerUser) ||
      (chargePayerType === "Branch" && !chargePayerBranch)
    )
      data.push("Charge payer is required");
    if (chargeAmount === "") data.push("Charge amount is required");

    setChargesIssues2(data);
    setChargesIssues2Valid(!data.length);
  }, [
    chargeCharge,
    chargeChargeType,
    chargePayerType,
    chargeAmount,
    chargePayerUser,
    chargePayerBranch,
  ]);

  // References Validation
  useEffect(() => {
    const data: string[] = [];

    setReferencesIssues(data);
    setReferencesValid(!data.length);
  }, [
    shipperReferenceNumber,
    forwarderReferenceNumer,
    purchaseOrderNumber,
    tariffNumber,
    contractPartyReferenceNumber,
    cosigneeReferenceNumber,
    BLReferenceNumber,
  ]);

  // Metadata useEffect
  useEffect(() => {
    setBranchSearch(branch?.Name || "");
    setUserSearch(user?.Name || "");
  }, [branch, user]);

  // Parties useEffect
  useEffect(() => {
    setCarrierSearch(carrier?.Name || "");
  }, [carrier]);

  // Charge useEffects
  useEffect(() => {
    if (typeof shipmentChargeIndex !== "number") return;

    const shipmentCharge = shipmentCharges[shipmentChargeIndex];

    setChargeCharge(shipmentCharge.chargeCharge);
    setChargeChargeType(shipmentCharge.chargeChargeType);
    setChargePayerType(shipmentCharge.chargePayerType);
    setChargePayerUser(shipmentCharge.chargePayerUser);
    setChargePayerBranch(shipmentCharge.chargePayerBranch);
    setChargeAmount(shipmentCharge.chargeAmount);
    setChargeCurrency(shipmentCharge.chargeCurrency);
  }, [shipmentChargeIndex, shipmentCharges]);

  useEffect(() => {
    setChargeChargeSearch(chargeCharge?.Name || "");
  }, [chargeCharge]);

  useEffect(() => {
    if (chargePayerUser) return setChargePayerSearch(chargePayerUser.Name);
    if (chargePayerBranch) return setChargePayerSearch(chargePayerBranch.Name);
    setChargePayerSearch("");
  }, [chargePayerUser, chargePayerBranch]);

  useEffect(() => {
    if (chargePayerType === "User") {
      setChargePayerBranch(null);
      setChargePayerUser(user);
    }
    if (chargePayerType === "Branch") {
      setChargePayerUser(null);
      setChargePayerBranch(branch);
    }
  }, [chargePayerType, branch, user]);

  // Add user to test
  useEffect(() => {
    if (!user) return;

    setShipmentCharges((charges) =>
      charges.map((charge) =>
        charge.chargePayerType === "User"
          ? { ...charge, chargePayerUser: user }
          : charge
      )
    );
  }, [user]);

  // Cargo useEffects
  useEffect(() => {
    if (typeof shipmentCargoIndex !== "number") return;

    const shipmentCargo = shipmentCargos[shipmentCargoIndex];

    setCargoDescription(shipmentCargo.cargoDescription);
    setCargoItemCode(shipmentCargo.cargoItemCode);
    setCargoQuantity(shipmentCargo.cargoQuantity);
    setCargoDeclaredValue(shipmentCargo.cargoDeclaredValue);
    setCargoPackagingType(shipmentCargo.cargoPackagingType);
    setCargoWeight(shipmentCargo.cargoWeight);
    setCargoWeightMeasure(shipmentCargo.cargoWeightMeasure);
    setCargoLength(shipmentCargo.cargoLength);
    setCargoBreadth(shipmentCargo.cargoBreadth);
    setCargoHeight(shipmentCargo.cargoHeight);
  }, [shipmentCargoIndex, shipmentCargos]);

  useEffect(() => {
    if (!selectedCargo) return;

    setCargoDescription(selectedCargo.Description);
    setCargoItemCode(selectedCargo.HSCode);
  }, [selectedCargo]);

  useEffect(() => {
    if (!cargoPackagingType) {
      setCargoWeight("");
      setCargoWeightMeasure("KG");
      setCargoLength("");
      setCargoBreadth("");
      setCargoHeight("");
    } else {
      setCargoWeight(cargoPackagingType.Weight);
      setCargoWeightMeasure(cargoPackagingType.WeightMeasure);
      setCargoLength(cargoPackagingType.Length);
      setCargoBreadth(cargoPackagingType.Breadth);
      setCargoHeight(cargoPackagingType.Height);
    }
  }, [cargoPackagingType]);

  // Destination useEffects
  useEffect(() => {
    if (!selectedDestinationTelephoneCountryId)
      return setDestinationTelephoneCountry(null);

    setDestinationTelephoneCountry(
      countries.find((ct) => ct._id === selectedDestinationTelephoneCountryId)!
    );
  }, [selectedDestinationTelephoneCountryId, countries]);

  useEffect(() => {
    if (!selectedDestinationTelephone2CountryId)
      return setDestinationTelephone2Country(null);

    setDestinationTelephone2Country(
      countries.find((ct) => ct._id === selectedDestinationTelephone2CountryId)!
    );
  }, [selectedDestinationTelephone2CountryId, countries]);

  useEffect(() => {
    if (!selectedDestination) return;

    setDestinationName(selectedDestination.Name);
    setDestinationCompany(selectedDestination.Company);
    setDestinationAddress(selectedDestination.Address);
    setDestinationAddress2(selectedDestination.Address2 || "");
    setDestinationAddress3(selectedDestination.Address3 || "");
    setDestinationSuburb(selectedDestination.Suburb || "");
    setDestinationCity(selectedDestination.City);
    setDestinationPostalCode(selectedDestination.PostalCode || "");
    setDestinationState(selectedDestination.State);
    setDestinationCountry(selectedDestination.Country);
    setDestinationEmailAddress(selectedDestination.EmailAddress || "");
    setDestinationTelephoneCountry(selectedDestination.TelephoneCountry);
    setDestinationTelephone(
      +selectedDestination.Telephone.slice(
        selectedDestination.Telephone.length - 10
      )
    );
    setDestinationTelephone2Country(
      selectedDestination.Telephone2Country || null
    );
    setDestinationTelephone2(
      selectedDestination.Telephone2
        ? +selectedDestination.Telephone2.slice(
            selectedDestination.Telephone2.length - 10
          )
        : ""
    );
    setDestinationIsResidential(selectedDestination.Residential);
    setDestinationSaveContact(false);
  }, [selectedDestination]);

  useEffect(() => {
    if (!destinationCountry) return;

    if (!destinationTelephoneCountry)
      setDestinationTelephoneCountry(destinationCountry);
    if (!destinationTelephone2Country)
      setDestinationTelephone2Country(destinationCountry);
  }, [
    destinationCountry,
    destinationTelephoneCountry,
    destinationTelephone2Country,
  ]);

  // Origin useEffects
  useEffect(() => {
    if (!selectedOriginTelephoneCountryId)
      return setOriginTelephoneCountry(null);

    setOriginTelephoneCountry(
      countries.find((ct) => ct._id === selectedOriginTelephoneCountryId)!
    );
  }, [selectedOriginTelephoneCountryId, countries]);

  useEffect(() => {
    if (!selectedOriginTelephone2CountryId)
      return setOriginTelephone2Country(null);

    setOriginTelephone2Country(
      countries.find((ct) => ct._id === selectedOriginTelephone2CountryId)!
    );
  }, [selectedOriginTelephone2CountryId, countries]);

  useEffect(() => {
    if (!selectedOrigin) return;

    setOriginName(selectedOrigin.Name);
    setOriginCompany(selectedOrigin.Company);
    setOriginAddress(selectedOrigin.Address);
    setOriginAddress2(selectedOrigin.Address2 || "");
    setOriginAddress3(selectedOrigin.Address3 || "");
    setOriginSuburb(selectedOrigin.Suburb || "");
    setOriginCity(selectedOrigin.City);
    setOriginPostalCode(selectedOrigin.PostalCode || "");
    setOriginState(selectedOrigin.State);
    setOriginCountry(selectedOrigin.Country);
    setOriginEmailAddress(selectedOrigin.EmailAddress || "");
    setOriginTelephoneCountry(selectedOrigin.TelephoneCountry);
    setOriginTelephone(
      +selectedOrigin.Telephone.slice(selectedOrigin.Telephone.length - 10)
    );
    setOriginTelephone2Country(selectedOrigin.Telephone2Country || null);
    setOriginTelephone2(
      selectedOrigin.Telephone2
        ? +selectedOrigin.Telephone2.slice(
            selectedOrigin.Telephone2.length - 10
          )
        : ""
    );
    setOriginIsResidential(selectedOrigin.Residential);
    setOriginSaveContact(false);
  }, [selectedOrigin]);

  useEffect(() => {
    if (!originCountry) return;

    if (!originTelephoneCountry) setOriginTelephoneCountry(originCountry);
    if (!originTelephone2Country) setOriginTelephone2Country(originCountry);
  }, [originCountry, originTelephoneCountry, originTelephone2Country]);

  useEffect(() => {
    const fillInAddress = (
      autocomplete: any,
      type: "Origin" | "Destination"
    ) => {
      const setAddress =
        type === "Origin" ? setOriginAddress : setDestinationAddress;
      const setAddress2 =
        type === "Origin" ? setOriginAddress2 : setDestinationAddress2;
      const setAddress3 =
        type === "Origin" ? setOriginAddress3 : setDestinationAddress3;
      const setSuburb =
        type === "Origin" ? setOriginSuburb : setDestinationSuburb;
      const setCity = type === "Origin" ? setOriginCity : setDestinationCity;
      const setPostalCode =
        type === "Origin" ? setOriginPostalCode : setDestinationPostalCode;
      const setState = type === "Origin" ? setOriginState : setDestinationState;
      const setCountry =
        type === "Origin" ? setOriginCountry : setDestinationCountry;

      setAddress("");
      setAddress2("");
      setAddress3("");
      setSuburb("");
      setCity("");
      setPostalCode("");
      setState("");
      setCountry(null);

      const place = autocomplete.getPlace();

      console.log("Latitude", place.geometry.location.lat());
      console.log("Longitude", place.geometry.location.lng());
      console.log(place);

      let street: string = "";
      let route: string = "";

      for (let i = 0; i < place.address_components.length; i++) {
        const addressType = place.address_components[i].types[0];

        if (addressType === "street_number") {
          street = place.address_components[i]["long_name"];
        }

        if (addressType === "route") {
          route = place.address_components[i]["long_name"];
        }

        if (!street && route) setAddress(route);
        if (street && !route) setAddress(street);
        if (street && route) setAddress(`${street}, ${route}`);

        if (addressType === "sublocality_level_1")
          setAddress(place.address_components[i]["long_name"]);

        if (addressType === "neighborhood")
          setSuburb(place.address_components[i]["long_name"]);

        if (addressType === "locality")
          setCity(place.address_components[i]["long_name"]);
        else if (addressType === "postal_town")
          setCity(place.address_components[i]["long_name"]);

        if (addressType === "administrative_area_level_1") {
          setState(place.address_components[i]["long_name"]);
        }

        if (addressType === "country") {
          const country =
            countries.find(
              (country) =>
                country.Code === place.address_components[i]["short_name"]
            ) || null;
          setCountry(country);
        }

        if (addressType === "postal_code")
          setPostalCode(place.address_components[i]["long_name"]);
      }
    };

    if (originAddressRef.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        originAddressRef.current,
        { types: ["geocode"] }
      );
      autocomplete.setFields(["address_component", "geometry"]);
      autocomplete.addListener("place_changed", () =>
        fillInAddress(autocomplete, "Origin")
      );
    }

    if (destinationAddressRef.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        destinationAddressRef.current,
        { types: ["geocode"] }
      );
      autocomplete.setFields(["address_component", "geometry"]);
      autocomplete.addListener("place_changed", () =>
        fillInAddress(autocomplete, "Destination")
      );
    }
  }, [countries, triggerAddressField]);

  // Set charge currency to nigeria(branch later)
  useEffect(() => {
    const naira =
      currencies.find((currency) => currency.Code === "NGN") || null;

    setChargeCurrency(naira);
  }, [currencies]);

  // Set currency to NGN for local and nationwide and USD for export
  useEffect(() => {
    if (["Local", "Nationwide"].includes(shipmentType))
      return setDeclaredValueCurrency(
        currencies.find((currency) => currency.Code === "NGN") || null
      );

    if (shipmentType === "Export")
      return setDeclaredValueCurrency(
        currencies.find((currency) => currency.Code === "USD") || null
      );
  }, [shipmentType, currencies]);

  // Set appropriate countries for different shipmentType
  // Local and Nationwide => origin and destination Country(Nigeria)
  // Export => origin country has to be nigeria
  // Import => destination country has to be nigeria
  useEffect(() => {
    if (!shipmentType) return;

    const nigeria = countries.find((country) => country.Code === "NG") || null;

    if (["Local", "Nationwide"].includes(shipmentType)) {
      if (!originCountry) setOriginCountry(nigeria);
      if (!destinationCountry) setDestinationCountry(nigeria);
    }

    if (shipmentType === "Export") {
      if (!originCountry) setOriginCountry(nigeria);
    }

    if (shipmentType === "Import") {
      if (!destinationCountry) setDestinationCountry(nigeria);
    }
  }, [shipmentType, countries, originCountry, destinationCountry]);

  // Load shipment for edit shipment
  useEffect(() => {
    if (!shipmentId) return;

    api_client({
      url: `/shipments/${shipmentId}/full`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        const shipmentData = res.data.data;

        setBranch(shipmentData.Branch);
        setUser(shipmentData.User);

        setShipmentDescription(shipmentData.Description);
        setShipmentType(shipmentData.Type);
        setInvoiceType(shipmentData.InvoiceType);
        setShippingTerm(shipmentData.ShippingTerm);
        setDeclaredValue(shipmentData.DeclaredValue);
        setDeclaredValueCurrency(shipmentData.ValueInsuranceCurrency);
        setCargoInsurance(shipmentData.CargoInsurance);
        setExportReason(shipmentData.ExportReason);

        setSelectedOrigin(shipmentData.Shipper);
        setPickupDate(getInputDate(shipmentData.PickupDate));
        setSelectedDestination(shipmentData.Cosignee);
        setDeliveryDate(getInputDate(shipmentData.DeliveryDate));

        setShipmentCargos(
          (shipmentData.Cargos as any[]).map((cargo) => ({
            cargoDescription: cargo.Description,
            cargoItemCode: cargo.ItemCode,
            cargoQuantity: cargo.Quantity,
            cargoDeclaredValue: cargo.DeclaredValue,
            cargoPackagingType: cargo.PackagingType || null,
            cargoWeight: cargo.Weight,
            cargoWeightMeasure: cargo.WeightMeasure,
            cargoLength: cargo.Length,
            cargoBreadth: cargo.Breadth,
            cargoHeight: cargo.Height,
          }))
        );

        setCarrier(shipmentData.Carrier);

        setShipmentCharges(
          (shipmentData.Charges as any[]).map((charge) => ({
            chargeCharge: charge.Charge,
            chargeChargeType: charge.Type,
            chargePayerType: charge.PayerOrPayeeType,
            chargePayerUser: charge.User || null,
            chargePayerBranch: charge.Branch || null,
            chargeAmount: charge.Amount,
            chargeCurrency: charge.Currency,
            chargePaymentLocation: charge.PaymentLocation,
            chargePaymentTerm: charge.PaymentTerm,
          }))
        );

        setShipperReferenceNumber(shipmentData.ShipperReference);
        setForwarderReferenceNumber(shipmentData.ForwarderReference);
        setContractPartyReferenceNumber(shipmentData.ContractPartyReference);
        setCosigneeReferenceNumber(shipmentData.CosigneeReference);
        setBLReferenceNumber(shipmentData.BLReference);
        setPurchaseOrderNumber(shipmentData.PurchaseOrderNumber);
        setTariffNumber(shipmentData.TariffNumber);
      })
      .catch((err) => {
        // navigate
      })
      .finally(() => {
        setLoading(false);
      });
  }, [shipmentId, accessToken]);

  useEffect(() => {
    fetchInvoiceTypes();
    fetchShippingTerms();
    fetchExportReasons();
    fetchCurrencies();
    fetchCountries();
    fetchImportantCharges();
  }, [
    fetchInvoiceTypes,
    fetchShippingTerms,
    fetchExportReasons,
    fetchCurrencies,
    fetchCountries,
    fetchImportantCharges,
  ]);

  const Metadata = (
    <div className="new-shipping__form">
      <div className="new-shipping__form-grid-1">
        <div className="form-group">
          <div className="label-flex">
            <label>
              Branch <span>*</span>
            </label>
          </div>
          <div
            className={cls("input-group", branch && "input-group--disabled")}
          >
            <input
              type="text"
              placeholder="Select Branch"
              value={branchSearch}
              onChange={(e) => setBranchSearch(e.target.value)}
              disabled={!!branch}
              onFocus={(e) => {
                setBranchSearchFocus(true);
              }}
              onBlur={() => {
                setBranchSearchFocus(false);
              }}
            />
            {branch && !adminBranch ? (
              <span
                className="input-group__clear"
                onClick={() => setBranch(null)}
              >
                <IonIcon icon={closeOutline} />
              </span>
            ) : null}
            <button
              onClick={() => (adminBranch ? null : openSelectBranch(setBranch))}
            >
              <IonIcon icon={adminBranch ? checkmarkOutline : searchOutline} />
            </button>
          </div>
          <SearchBox
            focus={branchSearchFocus}
            url="/branches?page=1&division=8&search={{search}}"
            search={branchSearch}
            displayKey="Name"
            clickHandler={setBranch}
          />
        </div>
        <div className="form-group">
          <div className="label-flex">
            <label>
              Customer <span>*</span>
            </label>
          </div>
          <div className={cls("input-group", user && "input-group--disabled")}>
            <input
              type="text"
              placeholder="Select Customer"
              value={userSearch}
              onChange={(e) => setUserSearch(e.target.value)}
              disabled={!!user}
              onFocus={(e) => {
                setUserSearchFocus(true);
              }}
              onBlur={() => {
                setUserSearchFocus(false);
              }}
            />
            {user && !shipmentId ? (
              <span
                className="input-group__clear"
                onClick={() => setUser(null)}
              >
                <IonIcon icon={closeOutline} />
              </span>
            ) : null}
            {shipmentId ? (
              <span className="input-group__clear">
                <IonIcon icon={checkmarkOutline} />
              </span>
            ) : null}
            {!shipmentId ? (
              <button onClick={() => openSelectUser(setUser)}>
                <IonIcon icon={searchOutline} />
              </button>
            ) : null}
          </div>
          <SearchBox
            focus={userSearchFocus}
            url="/users?page=1&division=8&search={{search}}"
            search={userSearch}
            displayKey="Name"
            clickHandler={setUser}
          />
        </div>
      </div>
    </div>
  );

  const BasicInformation = (
    <div className="new-shipping__form">
      <div className="form-group">
        <label>
          Shipment Description <span className="text-danger">*</span>
        </label>
        <textarea
          placeholder="Enter shipment description"
          cols={30}
          rows={5}
          className="form-input"
          value={shipmentDescription}
          onChange={(e) => setShipmentDescription(e.target.value)}
        ></textarea>
      </div>
      <div className="new-shipping__form-grid-1">
        <div className="form-group">
          <label>
            Shipment Type <span className="text-danger">*</span>
          </label>
          <select
            className="form-select"
            value={shipmentType}
            onChange={(e) => setShipmentType(e.target.value as tShipmentType)}
          >
            <option value="">-- Select Shipment Type --</option>
            {shippingTypes.map((shpType) => (
              <option key={shpType}>{shpType}</option>
            ))}
          </select>
        </div>
        {!shipmentType || ["Import", "Export"].includes(shipmentType) ? (
          <>
            <div className="form-group">
              <label>
                Invoice Type <span className="text-danger">*</span>
              </label>
              <select
                className="form-select"
                value={invoiceType!?._id || ""}
                onChange={compSelectHandler<tInvoiceType>(
                  invoiceTypes,
                  setInvoiceType
                )}
              >
                <option value="">-- Select Invoice Type --</option>
                {invoiceTypes.map((invType) => (
                  <option key={invType._id} value={invType._id}>
                    {invType.Name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Shipping Term</label>
              <select
                className="form-select"
                value={shippingTerm!?._id || ""}
                onChange={compSelectHandler<tShippingTerm>(
                  shippingTerms,
                  setShippingTerm
                )}
              >
                <option value="">-- Select Shipping Term --</option>
                {shippingTerms.map((shpTerm) => (
                  <option key={shpTerm._id} value={shpTerm._id}>
                    {shpTerm.Code}
                  </option>
                ))}
              </select>
            </div>
          </>
        ) : null}
        <div className="form-group">
          <label>
            Declared Value <span className="text-danger">*</span>
          </label>
          <div className="input-group">
            {["Local", "Nationwide"].includes(shipmentType) ? (
              <button>{declaredValueCurrency?.Code}</button>
            ) : null}
            <input
              type="text"
              placeholder="Enter declared value"
              value={declaredValue}
              onChange={(e) =>
                e.target.value
                  ? isNumber(e.target.value)
                    ? setDeclaredValue(e.target.value)
                    : null
                  : setDeclaredValue("")
              }
            />
          </div>
        </div>
        {!shipmentType || !["Local", "Nationwide"].includes(shipmentType) ? (
          <div className="form-group">
            <label>
              Declared Value Currency <span className="text-danger">*</span>
            </label>
            <select
              className="form-select"
              value={declaredValueCurrency!?._id || ""}
              onChange={compSelectHandler<tCurrency>(
                currencies,
                setDeclaredValueCurrency
              )}
            >
              <option value="">-- Select Currency --</option>
              {currencies.map((currcy) => (
                <option key={currcy._id} value={currcy._id}>
                  {currcy.Code}
                </option>
              ))}
            </select>
          </div>
        ) : null}
        {!shipmentType || ["Import", "Export"].includes(shipmentType) ? (
          <>
            <div className="form-group">
              <label>Cargo Insurance</label>
              <input
                type="text"
                className="form-input"
                placeholder="Enter cargo insurance"
                value={cargoInsurance}
                onChange={(e) =>
                  e.target.value
                    ? isNumber(e.target.value)
                      ? setCargoInsurance(e.target.value)
                      : null
                    : setCargoInsurance("")
                }
              />
            </div>
            <div className="form-group">
              <label>Reason for Export</label>
              <select
                className="form-select"
                value={exportReason!?._id || ""}
                onChange={compSelectHandler<tExportReason>(
                  exportReasons,
                  setExportReason
                )}
              >
                <option value="">-- Select Export Reason --</option>
                {exportReasons.map((expReason) => (
                  <option key={expReason._id} value={expReason._id}>
                    {expReason.Name}
                  </option>
                ))}
              </select>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );

  const Origin = (
    <div className="new-shipping__form">
      <div className="new-shipping__form-grid-1">
        <div className="form-group">
          <div className="label-flex">
            <label>
              Name <span>*</span>
            </label>
          </div>
          <div className="input-group">
            <input
              type="text"
              placeholder="Enter Name"
              value={originName}
              onChange={(e) => setOriginName(e.target.value)}
              onFocus={(e) => {
                setOriginNameFocus(true);
              }}
              onBlur={() => {
                setOriginNameFocus(false);
              }}
            />
            <button onClick={() => openSelectContact(setSelectedOrigin)}>
              <IonIcon icon={searchOutline} />
            </button>
          </div>
          <SearchBox
            focus={originNameFocus}
            url="/contacts?page=1&division=8&search={{search}}"
            search={originName}
            displayKey="Name"
            clickHandler={setSelectedOrigin}
          />
        </div>
        <div className="form-group">
          <label>
            Company <span>*</span>
          </label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter company"
            value={originCompany}
            onChange={(e) => setOriginCompany(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>
            Pickup Date <span>*</span>
          </label>
          <input
            type="date"
            className="form-input"
            value={pickupDate}
            onChange={(e) => setPickupDate(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>
            Address <span>*</span>
          </label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter address"
            value={originAddress}
            onChange={(e) => setOriginAddress(e.target.value)}
            ref={(el) => {
              originAddressRef.current = el;
              setTriggerAddressField((taf) => !taf);
            }}
          />
        </div>
        <div className="form-group">
          <label>Address 2</label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter address 2"
            value={originAddress2}
            onChange={(e) => setOriginAddress2(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Address 3</label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter address 3"
            value={originAddress3}
            onChange={(e) => setOriginAddress3(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Suburb</label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter suburb"
            value={originSuburb}
            onChange={(e) => setOriginSuburb(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Postal code</label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter postal code"
            value={originPostalCode}
            onChange={(e) => setOriginPostalCode(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>
            Country <span>*</span>
          </label>
          <select
            className="form-select"
            value={originCountry!?._id || ""}
            onChange={compSelectHandler<tCountry>(countries, setOriginCountry)}
          >
            <option value="">-- Select Country --</option>
            {countries.map((cuntry) => (
              <option key={cuntry._id} value={cuntry._id}>
                {cuntry.Name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>
            State <span>*</span>
          </label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter state"
            value={originState}
            onChange={(e) => setOriginState(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>
            City <span>*</span>
          </label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter city"
            value={originCity}
            onChange={(e) => setOriginCity(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Email address</label>
          <input
            type="email"
            className="form-input"
            placeholder="Enter email address"
            value={originEmailAddress}
            onChange={(e) => setOriginEmailAddress(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>
            Phone Number <span>*</span>
          </label>
          <div className="input-group">
            <button onClick={() => openOriginTelephoneCountrySelectBox()}>
              {originTelephoneCountry
                ? originTelephoneCountry.TelephoneCode
                : "Select"}{" "}
              <IonIcon icon={chevronDownOutline} />
            </button>
            <input
              type="text"
              placeholder="Enter Phone Number 1"
              value={originTelephone}
              onChange={(e) =>
                e.target.value
                  ? isNumber(e.target.value)
                    ? setOriginTelephone(+e.target.value)
                    : null
                  : setOriginTelephone("")
              }
              maxLength={10}
            />
          </div>
        </div>
        <div className="form-group">
          <label>Phone Number 2</label>
          <div className="input-group">
            <button onClick={() => openOriginTelephone2CountrySelectBox()}>
              {originTelephone2Country
                ? originTelephone2Country.TelephoneCode
                : "Select"}{" "}
              <IonIcon icon={chevronDownOutline} />
            </button>
            <input
              type="text"
              placeholder="Enter Phone Number 2"
              value={originTelephone2}
              onChange={(e) =>
                e.target.value
                  ? isNumber(e.target.value)
                    ? setOriginTelephone2(+e.target.value)
                    : null
                  : setOriginTelephone2("")
              }
              maxLength={10}
            />
          </div>
        </div>
        <div className="new-shipping__checks">
          <div className="accept">
            <input
              type="checkbox"
              className="accept__checkbox"
              checked={originIsResidential}
              onChange={(e) => setOriginIsResidential(e.target.checked)}
            />
            <p>Residential</p>
          </div>
          <div className="accept">
            <input
              type="checkbox"
              className="accept__checkbox"
              checked={originSaveContact}
              onChange={(e) => setOriginSaveContact(e.target.checked)}
            />
            <p>Save Contact</p>
          </div>
        </div>
      </div>
    </div>
  );

  const Destination = (
    <div className="new-shipping__form">
      <div className="new-shipping__form-grid-1">
        <div className="form-group">
          <div className="label-flex">
            <label>
              Name <span>*</span>
            </label>
          </div>
          <div className="input-group">
            <input
              type="text"
              placeholder="Enter Name"
              value={destinationName}
              onChange={(e) => setDestinationName(e.target.value)}
              onFocus={(e) => {
                setDestinationNameFocus(true);
              }}
              onBlur={() => {
                setDestinationNameFocus(false);
              }}
            />
            <button onClick={() => openSelectContact(setSelectedDestination)}>
              <IonIcon icon={searchOutline} />
            </button>
          </div>
          <SearchBox
            focus={destinationNameFocus}
            url="/contacts?page=1&division=8&search={{search}}"
            search={destinationName}
            displayKey="Name"
            clickHandler={setSelectedDestination}
          />
        </div>
        <div className="form-group">
          <label>
            Company <span>*</span>
          </label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter company"
            value={destinationCompany}
            onChange={(e) => setDestinationCompany(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>
            Delivery Date <span>*</span>
          </label>
          <input
            type="date"
            className="form-input"
            value={deliveryDate}
            onChange={(e) => setDeliveryDate(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>
            Address <span>*</span>
          </label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter address"
            value={destinationAddress}
            onChange={(e) => setDestinationAddress(e.target.value)}
            ref={(el) => {
              destinationAddressRef.current = el;
              setTriggerAddressField((taf) => !taf);
            }}
          />
        </div>
        <div className="form-group">
          <label>Address 2</label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter address 2"
            value={destinationAddress2}
            onChange={(e) => setDestinationAddress2(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Address 3</label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter address 3"
            value={destinationAddress3}
            onChange={(e) => setDestinationAddress3(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Suburb</label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter suburb"
            value={destinationSuburb}
            onChange={(e) => setDestinationSuburb(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Postal code</label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter postal code"
            value={destinationPostalCode}
            onChange={(e) => setDestinationPostalCode(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>
            Country <span>*</span>
          </label>
          <select
            className="form-select"
            value={destinationCountry!?._id || ""}
            onChange={compSelectHandler<tCountry>(
              countries,
              setDestinationCountry
            )}
          >
            <option value="">-- Select Country --</option>
            {countries.map((cuntry) => (
              <option key={cuntry._id} value={cuntry._id}>
                {cuntry.Name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>
            State <span>*</span>
          </label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter state"
            value={destinationState}
            onChange={(e) => setDestinationState(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>
            City <span>*</span>
          </label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter city"
            value={destinationCity}
            onChange={(e) => setDestinationCity(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Email address</label>
          <input
            type="email"
            className="form-input"
            placeholder="Enter email address"
            value={destinationEmailAddress}
            onChange={(e) => setDestinationEmailAddress(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>
            Phone Number <span>*</span>
          </label>
          <div className="input-group">
            <button onClick={() => openDestinationTelephoneCountrySelectBox()}>
              {destinationTelephoneCountry
                ? destinationTelephoneCountry.TelephoneCode
                : "Select"}{" "}
              <IonIcon icon={chevronDownOutline} />
            </button>
            <input
              type="text"
              placeholder="Enter Phone Number 1"
              value={destinationTelephone}
              onChange={(e) =>
                e.target.value
                  ? isNumber(e.target.value)
                    ? setDestinationTelephone(+e.target.value)
                    : null
                  : setDestinationTelephone("")
              }
              maxLength={10}
            />
          </div>
        </div>
        <div className="form-group">
          <label>Phone Number 2</label>
          <div className="input-group">
            <button onClick={() => openDestinationTelephone2CountrySelectBox()}>
              {destinationTelephone2Country
                ? destinationTelephone2Country.TelephoneCode
                : "Select"}{" "}
              <IonIcon icon={chevronDownOutline} />
            </button>
            <input
              type="text"
              placeholder="Enter Phone Number 2"
              value={destinationTelephone2}
              onChange={(e) =>
                e.target.value
                  ? isNumber(e.target.value)
                    ? setDestinationTelephone2(+e.target.value)
                    : null
                  : setDestinationTelephone2("")
              }
              maxLength={10}
            />
          </div>
        </div>
        <div className="new-shipping__checks">
          <div className="accept">
            <input
              type="checkbox"
              className="accept__checkbox"
              checked={destinationIsResidential}
              onChange={(e) => setDestinationIsResidential(e.target.checked)}
            />
            <p>Residential</p>
          </div>
          <div className="accept">
            <input
              type="checkbox"
              className="accept__checkbox"
              checked={destinationSaveContact}
              onChange={(e) => setDestinationSaveContact(e.target.checked)}
            />
            <p>Save Contact</p>
          </div>
        </div>
      </div>
    </div>
  );

  const CargoDetails = (
    <>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>S/N</th>
              <th>Cargo Description</th>
              <th>Item Code</th>
              <th>Weight</th>
              {/* <th>Gross Volume</th> */}
              <th>Packaging</th>
              <th>Value</th>
              <th>Qty</th>
              <th>Dimensions</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {!shipmentCargos.length ? (
              <tr>
                <td colSpan={10} className="text-center">
                  You have not added any cargo.{" "}
                  <span className="link">Add below</span>
                </td>
              </tr>
            ) : null}
            {shipmentCargos.map((shipmentCargo, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{shipmentCargo.cargoDescription}</td>
                <td>{shipmentCargo.cargoItemCode || "N/A"}</td>
                <td>
                  {shipmentCargo.cargoWeight * shipmentCargo.cargoQuantity}{" "}
                  {shipmentCargo.cargoWeightMeasure}
                </td>
                {/* <td>5kg</td> */}
                <td>
                  {shipmentCargo.cargoPackagingType?.Name ||
                    "Your own Packaging"}
                </td>
                <td>
                  {declaredValueCurrency?.Symbol}{" "}
                  {shipmentCargo.cargoDeclaredValue}
                </td>
                <td>{shipmentCargo.cargoQuantity}</td>
                <td>
                  {`${shipmentCargo.cargoLength} x ${shipmentCargo.cargoBreadth} x 
                  ${shipmentCargo.cargoHeight}`}
                </td>
                <td>
                  <div className="table__actions">
                    <span
                      className="table__action"
                      title="Edit"
                      onClick={() => setShipmentCargoIndex(i)}
                    >
                      <IonIcon icon={createOutline} />
                    </span>
                    <span
                      className="table__action"
                      title="Delete"
                      onClick={() => removeShipmentCargo(i)}
                    >
                      <IonIcon icon={trashOutline} />
                    </span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="new-shipping__form">
        <div className="new-shipping__form-grid-1">
          <div className="form-group">
            <div className="label-flex">
              <label>
                Cargo Description <span>*</span>
              </label>
            </div>
            <div className={"input-group"}>
              <input
                type="text"
                placeholder="Enter cargo description"
                value={cargoDescription}
                onChange={(e) => setCargoDescription(e.target.value)}
                onFocus={(e) => {
                  setCargoDescriptionFocus(true);
                }}
                onBlur={() => {
                  setCargoDescriptionFocus(false);
                }}
              />
              <button onClick={() => openSelectCargo(setSelectedCargo)}>
                <IonIcon icon={searchOutline} />
              </button>
            </div>
            <SearchBox
              focus={cargoDescriptionFocus}
              url="/cargos?page=1&division=8&search={{search}}"
              search={cargoDescription}
              displayKey="Description"
              clickHandler={setSelectedCargo}
            />
          </div>
          <div className="form-group">
            <label>
              Item Code {shipmentType === "Export" ? <span>*</span> : null}
            </label>
            <input
              type="text"
              className="form-input"
              placeholder="Enter code"
              value={cargoItemCode}
              onChange={(e) => setCargoItemCode(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>
              Quantity <span>*</span>
            </label>
            <input
              type="text"
              className="form-input"
              placeholder="Enter quantity"
              value={cargoQuantity}
              onChange={(e) =>
                e.target.value
                  ? isNumber(e.target.value)
                    ? setCargoQuantity(+e.target.value)
                    : null
                  : setCargoQuantity("")
              }
            />
          </div>
          <div className="form-group">
            <label>
              Declared Value <span>*</span>
            </label>
            <div className="input-group">
              {declaredValueCurrency ? (
                <button>{declaredValueCurrency.Code}</button>
              ) : null}
              <input
                type="text"
                placeholder="Enter declared value"
                value={cargoDeclaredValue}
                onChange={(e) =>
                  e.target.value
                    ? isNumber(e.target.value)
                      ? setCargoDeclaredValue(e.target.value)
                      : null
                    : setCargoDeclaredValue("")
                }
              />
            </div>
          </div>
          <div className="form-group">
            <div className="label-flex">
              <label>Packaging Type</label>
              {cargoPackagingType ? (
                <span onClick={() => setCargoPackagingType(null)}>
                  Clear Selection
                </span>
              ) : null}
            </div>
            <div className="input-group">
              <input
                type="text"
                placeholder="Start typing name"
                value={cargoPackagingType?.Name || "Your own package"}
                readOnly
              />
              <button
                onClick={() => openSelectPackagingType(setCargoPackagingType)}
              >
                <IonIcon icon={searchOutline} />
              </button>
            </div>
          </div>
          <div className="form-group">
            <label>
              Weight <span>*</span>
            </label>
            <div
              className={cls(
                "input-group",
                cargoPackagingType && "input-group--disabled"
              )}
            >
              <input
                type="text"
                placeholder="Enter weight"
                value={cargoWeight}
                onChange={(e) =>
                  e.target.value
                    ? isNumber(e.target.value)
                      ? setCargoWeight(e.target.value)
                      : null
                    : setCargoWeight("")
                }
                disabled={!!cargoPackagingType}
              />
              <button>{cargoWeightMeasure}</button>
            </div>
          </div>
          <div className="form-group">
            <label>Dimensions</label>
            <div className="new-shipping__form-grid-1">
              <input
                type="text"
                className="form-input"
                placeholder="Length"
                value={cargoLength}
                onChange={(e) =>
                  e.target.value
                    ? isNumber(e.target.value)
                      ? setCargoLength(e.target.value)
                      : null
                    : setCargoLength("")
                }
                disabled={!!cargoPackagingType}
              />
              <input
                type="text"
                className="form-input"
                placeholder="Breadth"
                value={cargoBreadth}
                onChange={(e) =>
                  e.target.value
                    ? isNumber(e.target.value)
                      ? setCargoBreadth(e.target.value)
                      : null
                    : setCargoBreadth("")
                }
                disabled={!!cargoPackagingType}
              />
              <input
                type="text"
                className="form-input"
                placeholder="Height"
                value={cargoHeight}
                onChange={(e) =>
                  e.target.value
                    ? isNumber(e.target.value)
                      ? setCargoHeight(e.target.value)
                      : null
                    : setCargoHeight("")
                }
                disabled={!!cargoPackagingType}
              />
            </div>
          </div>
          <div className="flex-bottom">
            <button className="btn btn--info" onClick={addUpdateShipmentCargo}>
              <IonIcon
                icon={
                  typeof shipmentCargoIndex === "number"
                    ? checkmarkOutline
                    : addOutline
                }
              />
              {typeof shipmentCargoIndex === "number" ? "Save" : "Add"}
            </button>
          </div>
        </div>
      </div>
    </>
  );

  const Parties = (
    <div className="new-shipping__form">
      <div className="new-shipping__form-grid-1">
        <div className="form-group">
          <div className="label-flex">
            <label>
              Carrier <span>*</span>
            </label>
          </div>
          <div
            className={cls("input-group", carrier && "input-group--disabled")}
          >
            <input
              type="text"
              placeholder="Select Carrier"
              value={carrierSearch}
              onChange={(e) => setCarrierSearch(e.target.value)}
              disabled={!!carrier}
              onFocus={(e) => {
                setCarrierSearchFocus(true);
              }}
              onBlur={() => {
                setCarrierSearchFocus(false);
              }}
            />
            {carrier ? (
              <span
                className="input-group__clear"
                onClick={() => setCarrier(null)}
              >
                <IonIcon icon={closeOutline} />
              </span>
            ) : null}
            <button onClick={() => openSelectCarrier(setCarrier)}>
              <IonIcon icon={searchOutline} />
            </button>
          </div>
          <SearchBox
            focus={carrierSearchFocus}
            url="/carriers?page=1&division=8&search={{search}}"
            search={carrierSearch}
            displayKey="Name"
            clickHandler={setCarrier}
          />
        </div>
      </div>
    </div>
  );

  const Charges = (
    <>
      {shipmentType !== "Local" && carrier && carrier.Code === "DHL" ? (
        <div className="flex-center">
          <button className="btn btn--info" onClick={searchRate}>
            <IonIcon icon={searchOutline} />
            {shipmentId ? "Refresh" : "Search"} Rate
          </button>
        </div>
      ) : null}
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>S/N</th>
              <th>Charge</th>
              <th>Type</th>
              <th>Payer</th>
              <th>Amount</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {!shipmentCharges.length ? (
              <tr>
                <td colSpan={10} className="text-center">
                  You have not added any charges.{" "}
                  <span className="link">Add below</span>
                </td>
              </tr>
            ) : null}
            {shipmentCharges.map((shipmentCharge, i) => (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{shipmentCharge.chargeCharge.Name}</td>
                <td>
                  {shipmentCharge.chargeChargeType === "INCOME"
                    ? "Income Charge"
                    : "Expense Charge"}
                </td>
                <td>
                  {shipmentCharge.chargePayerType === "User" &&
                  shipmentCharge.chargePayerUser
                    ? `${shipmentCharge.chargePayerUser.Name}`
                    : ""}
                  {shipmentCharge.chargePayerType === "Branch" &&
                  shipmentCharge.chargePayerBranch
                    ? shipmentCharge.chargePayerBranch.Name
                    : ""}
                </td>
                <td>
                  {shipmentCharge.chargeCurrency.Code}{" "}
                  {shipmentCharge.chargeAmount}
                </td>
                <td>
                  <div className="table__actions">
                    <span
                      className="table__action"
                      title="Edit"
                      onClick={() => setShipmentChargeIndex(i)}
                    >
                      <IonIcon icon={createOutline} />
                    </span>
                    <span
                      className="table__action"
                      title="Delete"
                      onClick={() => removeShipmentCharge(i)}
                    >
                      <IonIcon icon={trashOutline} />
                    </span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="new-shipping__form">
        <div className="new-shipping__form-grid-1">
          <div className="form-group">
            <label>
              Charge Description <span>*</span>
            </label>
            <div
              className={cls(
                "input-group",
                chargeCharge && "input-group--disabled"
              )}
            >
              <input
                type="text"
                placeholder="Select Charge Description"
                value={chargeChargeSearch}
                onChange={(e) => setChargeChargeSearch(e.target.value)}
                disabled={!!chargeCharge}
                onFocus={(e) => {
                  setChargeChargeSearchFocus(true);
                }}
                onBlur={() => {
                  setChargeChargeSearchFocus(false);
                }}
              />
              {chargeCharge ? (
                <span
                  className="input-group__clear"
                  onClick={() => setChargeCharge(null)}
                >
                  <IonIcon icon={closeOutline} />
                </span>
              ) : null}
              <button onClick={() => openSelectCharge(setChargeCharge)}>
                <IonIcon icon={searchOutline} />
              </button>
            </div>
            <SearchBox
              focus={chargeChargeSearchFocus}
              url="/charges?page=1&division=8&search={{search}}"
              search={chargeChargeSearch}
              displayKey="Name"
              clickHandler={setChargeCharge}
            />
          </div>
          <div className="form-group">
            <label>
              Charge Type <span>*</span>
            </label>
            <select
              className="form-select"
              value={chargeChargeType}
              onChange={(e) =>
                setChargeChargeType(e.target.value as tChargeType)
              }
            >
              <option value="">-- Select charge type --</option>
              {chargeTypes.map((chrgType) => (
                <option key={chrgType.value} value={chrgType.value}>
                  {chrgType.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>
              Select Payer / Payee Type <span>*</span>
            </label>
            <select
              value={chargePayerType}
              onChange={(e) =>
                setChargePayerType(e.target.value as "" | "User" | "Branch")
              }
              className="form-select"
            >
              <option value="">-- Select Payer / Payee Type --</option>
              <option>Branch</option>
              <option value="User">Customer</option>
            </select>
          </div>
          {chargePayerType === "Branch" ? (
            <div className="form-group">
              <label>
                Payer / Payee (Branch) <span>*</span>
              </label>
              <div
                className={cls(
                  "input-group",
                  chargePayerBranch && "input-group--disabled"
                )}
              >
                <input
                  type="text"
                  placeholder="Select Payer / Payee"
                  value={chargePayerSearch}
                  onChange={(e) => setChargePayerSearch(e.target.value)}
                  disabled={!!chargePayerBranch}
                  onFocus={(e) => {
                    setChargePayerSearchFocus(true);
                  }}
                  onBlur={() => {
                    setChargePayerSearchFocus(false);
                  }}
                />
                {chargePayerBranch ? (
                  <span
                    className="input-group__clear"
                    onClick={() => setChargePayerBranch(null)}
                  >
                    <IonIcon icon={closeOutline} />
                  </span>
                ) : null}
                <button onClick={() => openSelectBranch(setChargePayerBranch)}>
                  <IonIcon icon={searchOutline} />
                </button>
              </div>
              <SearchBox
                focus={chargePayerSearchFocus}
                url="/branches?page=1&division=8&search={{search}}"
                search={chargePayerSearch}
                displayKey="Name"
                clickHandler={setChargePayerBranch}
              />
            </div>
          ) : null}
          {chargePayerType === "User" ? (
            <div className="form-group">
              <div className="label-flex">
                <label>
                  Payer / Payee (Customer) <span>*</span>
                </label>
              </div>
              <div
                className={cls("input-box", user && "input-box--disabled")}
                onClick={() => {
                  if (!user)
                    document
                      .getElementById("metadata")
                      ?.scrollIntoView({ behavior: "smooth" });
                }}
              >
                {user ? user.Name : "Select Customer"}{" "}
                <IonIcon icon={user ? checkmarkOutline : arrowUpOutline} />
              </div>
            </div>
          ) : null}
          <div className="form-group">
            <label>
              Amount <span>*</span>
            </label>
            <div className="input-group">
              {chargeCurrency ? <button>{chargeCurrency.Code}</button> : null}
              <input
                type="text"
                placeholder="Enter amount"
                value={chargeAmount}
                onChange={(e) =>
                  e.target.value
                    ? isNumber(e.target.value)
                      ? setChargeAmount(e.target.value)
                      : null
                    : setChargeAmount("")
                }
              />
            </div>
          </div>
          <div className="flex-bottom">
            <button className="btn btn--info" onClick={addUpdateShipmentCharge}>
              <IonIcon
                icon={
                  typeof shipmentChargeIndex === "number"
                    ? checkmarkOutline
                    : addOutline
                }
              />
              {typeof shipmentChargeIndex === "number" ? "Save" : "Add"}
            </button>
          </div>
        </div>
      </div>
    </>
  );

  const References = (
    <div className="new-shipping__form">
      <div className="new-shipping__form-grid-1">
        <div className="form-group">
          <label>Shipper's Reference Number(s)</label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter reference number"
            value={shipperReferenceNumber}
            onChange={(e) => setShipperReferenceNumber(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Forwarders's Reference Number(s)</label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter reference number"
            value={forwarderReferenceNumer}
            onChange={(e) => setForwarderReferenceNumber(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Purchase Order Number(s)</label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter number"
            value={purchaseOrderNumber}
            onChange={(e) => setPurchaseOrderNumber(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Tariff Number</label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter number"
            value={tariffNumber}
            onChange={(e) => setTariffNumber(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Contract Party Reference Number(s)</label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter reference number"
            value={contractPartyReferenceNumber}
            onChange={(e) => setContractPartyReferenceNumber(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Cosignee's Reference Number(s)</label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter reference number"
            value={cosigneeReferenceNumber}
            onChange={(e) => setCosigneeReferenceNumber(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>BL Reference Number(s)</label>
          <input
            type="text"
            className="form-input"
            placeholder="Enter reference number"
            value={BLReferenceNumber}
            onChange={(e) => setBLReferenceNumber(e.target.value)}
          />
        </div>
      </div>
    </div>
  );

  const sections = [
    {
      name: "Metadata",
      hash: "metadata",
      availableIn: ["Local", "Nationwide", "Import", "Export"],
      show: true,
      component: Metadata,
      done: metadataValid,
      validations: [metadataIssues],
    },
    {
      name: "Basic Information",
      hash: "basic-info",
      availableIn: ["Local", "Nationwide", "Import", "Export"],
      show: true,
      component: BasicInformation,
      done: basicInfoValid,
      validations: [basicInfoIssues],
    },
    {
      name: "Origin (Shipper)",
      hash: "origin",
      availableIn: ["Local", "Nationwide", "Import", "Export"],
      show: true,
      component: Origin,
      done: originValid,
      validations: [originIssues],
    },
    {
      name: "Destination (Cosignee)",
      hash: "destination",
      availableIn: ["Local", "Nationwide", "Import", "Export"],
      show: true,
      component: Destination,
      done: destinationValid,
      validations: [destinationIssues],
    },
    {
      name: "Cargo Details",
      hash: "cargo-details",
      availableIn: ["Nationwide", "Import", "Export"],
      show: true,
      component: CargoDetails,
      done: cargoIssues1Valid,
      validations: [cargoIssues1],
    },
    {
      name: "Parties",
      hash: "parties",
      availableIn: ["Local", "Nationwide", "Import", "Export"],
      show: true,
      component: Parties,
      done: partiesValid,
      validations: [partiesIssues],
    },
    {
      name: "Charges",
      hash: "charges",
      availableIn: ["Local", "Nationwide", "Import", "Export"],
      show: true,
      component: Charges,
      done: chargesIssues1Valid,
      validations: [chargesIssues1],
    },
    {
      name: "References",
      hash: "references",
      availableIn: ["Import", "Export"],
      show: true,
      component: References,
      done: referencesValid,
      validations: [referencesIssues],
    },
  ];

  const availableSections = sections.filter(
    (section) =>
      section.show &&
      (!shipmentType ||
        (shipmentType && section.availableIn.includes(shipmentType)))
  );

  return (
    <>
      {selectContact}
      {selectCarrier}

      {originTelephoneCountrySelectBox}
      {originTelephone2CountrySelectBox}

      {destinationTelephoneCountrySelectBox}
      {destinationTelephone2CountrySelectBox}

      {selectCargo}
      {selectPackagingType}

      {selectCharge}
      {selectBranch}
      {selectUser}

      {showSpinner ? <Spinner /> : null}

      {alertMessages.length ? (
        <div>
          <div className="success-modal">
            <div className="icon warning">
              <span className="body"></span>
              <span className="dot"></span>
            </div>
            {alertMessages.map((message, i) => (
              <p key={i} className="text-center">
                {message}
              </p>
            ))}
          </div>
          <div className="overlay" onClick={() => setAlertMessages([])}></div>
        </div>
      ) : null}
      {rateInfo ? (
        <div>
          <div className="success-modal">
            <div className="success-checkmark">
              <div className="check-icon">
                <span className="icon-line line-tip"></span>
                <span className="icon-line line-long"></span>
                <div className="icon-circle"></div>
                <div className="icon-fix"></div>
              </div>
            </div>
            <h3>Shipping Rate!</h3>
            <p>
              The shipping rate for {rateInfo.totalWeight}kg total weight on{" "}
              {rateInfo.pickupDate} is NGN {rateInfo.rate}
            </p>
            <div className="success-modal__btns">
              <button
                className="btn"
                onClick={() => {
                  setRateInfo(null);
                }}
              >
                Back
              </button>
              <button
                className="btn btn--success"
                onClick={() => {
                  setShipmentCharges((shipCharges) =>
                    shipCharges.filter(
                      (charge) =>
                        !["FRC", "DLF"].includes(charge.chargeCharge.Code)
                    )
                  );

                  setChargeCharge(importantCharges.FRC);
                  setChargeChargeType("EXPENSE");
                  setChargePayerType("");
                  setChargeAmount(+rateInfo.rate);
                  const naira =
                    currencies.find((currency) => currency.Code === "NGN") ||
                    null;
                  setChargeCurrency(naira);

                  setRateInfo(null);
                }}
              >
                Accept
              </button>
            </div>
          </div>
          <div className="overlay" onClick={() => setRateInfo(null)}></div>
        </div>
      ) : null}
      {showSuccessModal ? (
        <div>
          <div className="success-modal">
            <div className="success-checkmark">
              <div className="check-icon">
                <span className="icon-line line-tip"></span>
                <span className="icon-line line-long"></span>
                <div className="icon-circle"></div>
                <div className="icon-fix"></div>
              </div>
            </div>
            <h3>Success</h3>
            <p>Shipment {shipmentId ? "updated" : "created"} successfully</p>
            <div className="success-modal__btns">
              <button
                className="btn btn--info"
                onClick={() => {
                  newShipment.current
                    ? navigate(`/edit-shipment/${newShipment.current}`)
                    : window.location.reload();
                }}
              >
                Back
              </button>
              <button
                className="btn btn--success"
                onClick={() => navigate("/shipments")}
              >
                Next
              </button>
            </div>
          </div>
          <div className="overlay"></div>
        </div>
      ) : null}
      {showConfirmationModal ? (
        <div>
          <div className="success-modal">
            <h3>Confirmation</h3>
            <p className="text-center">
              Are you sure you want to proceed with{" "}
              {shipmentId ? "updating" : "creating"} this shipment
            </p>
            <div className="success-modal__btns">
              <button
                className="btn btn--info"
                onClick={() => {
                  setShowConfirmationModal(false);
                }}
              >
                Cancel
              </button>
              <button className="btn btn--success" onClick={createShipment}>
                Continue
              </button>
            </div>
          </div>
          <div
            className="overlay"
            onClick={() => setShowConfirmationModal(false)}
          ></div>
        </div>
      ) : null}

      <Header />
      <main className="main">
        <div className="container">
          {loading ? (
            <VerticalBarLoader />
          ) : (
            <>
              <div className="new-shipping">
                <aside className="new-shipping__aside">
                  {availableSections.map((section, i) => (
                    <div
                      className={cls("step", section.done && "step--done")}
                      key={i}
                      onClick={() => {
                        document
                          .getElementById(section.hash)
                          ?.scrollIntoView({ behavior: "smooth" });
                      }}
                    >
                      <button className="step__btn">
                        <span>{i + 1}</span>
                        <IonIcon icon={checkmarkOutline} />
                      </button>
                      <p>{section.name}</p>
                    </div>
                  ))}
                </aside>
                <section className="new-shipping__main">
                  {availableSections.map((section, i) => (
                    <div
                      className="new-shipping__form-section"
                      key={i}
                      id={section.hash}
                    >
                      <div className="new-shipping__form-header">
                        <p>
                          {i + 1}. {section.name}
                        </p>
                        {!section.done ? (
                          <div className="new-shipping__info">
                            <div className="new-shipping__info-main">
                              <IonIcon icon={alertCircle} />
                            </div>
                            <div className="new-shipping__info-block">
                              <div className="new-shipping__info-block-main">
                                <div>
                                  <h3>{section.name} Validations</h3>
                                  <ul>
                                    {section.validations[0].map((issue, i) => (
                                      <li key={i}>
                                        <IonIcon icon={alertCircle} />
                                        <p>{issue}</p>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                              <div className="new-shipping__info-block-top"></div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      {section.component}
                    </div>
                  ))}
                  <div className="new-shipping__form-section">
                    <div className="new-shipping__actions">
                      <button
                        className="button button--primary"
                        onClick={() =>
                          availableSections
                            .map((section) => section.done)
                            .some((v) => !v)
                            ? setAlertMessages([
                                "Please provide valid shipping informations",
                              ])
                            : setShowConfirmationModal(true)
                        }
                      >
                        {shipmentId ? "Update" : "Create"} Shipment
                      </button>
                    </div>
                  </div>
                </section>
              </div>
            </>
          )}
        </div>
      </main>
    </>
  );
};

export default withAuth(NewShipping);
