import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";

import { getState, saveState } from "./global";

import { Entries } from "../utils/utils.types";
import {
  tCarriers,
  tCharge,
  tChargeTypes,
  tExportReasons,
  tInvoiceTypes,
  tShipmentTypes,
  tShippingTerms,
  tWeightMeasures,
} from "./types/shipping.types";
import {
  tBranches,
  tBusinessSectors,
  tCountries,
  tCurrencies,
  tOverview,
} from "./types/app.types";

import { SelectBoxDataType } from "../components/SelectBox/SelectBox";

type CacheType = {
  shippingTypes: tShipmentTypes;
  invoiceTypes: tInvoiceTypes;
  shippingTerms: tShippingTerms;
  exportReasons: tExportReasons;
  currencies: tCurrencies;
  countries: tCountries;
  countriesSelectData: SelectBoxDataType;
  weightMeasures: tWeightMeasures;
  chargeTypes: tChargeTypes;
  importantCharges: {
    [key in "DLF" | "FRC"]: tCharge | null;
  };
  overview: tOverview | null;
  carriers: tCarriers;
  branches: tBranches;
  businessSectors: tBusinessSectors;
  transportTypes: { _id: string; Name: string }[];
};

const CacheInitialState: CacheType = {
  shippingTypes: ["Local", "Nationwide", "Export"],
  invoiceTypes: [],
  shippingTerms: [],
  exportReasons: [],
  currencies: [],
  countries: [],
  countriesSelectData: [],
  weightMeasures: ["KG"],
  chargeTypes: [
    { value: "EXPENSE", name: "Expense Charge" },
    { value: "INCOME", name: "Income Charge" },
  ],
  importantCharges: {
    FRC: null,
    DLF: null,
  },
  overview: null,
  carriers: [],
  branches: [],
  businessSectors: [],
  transportTypes: [],
};

const cacheSlice = createSlice({
  name: "cache",
  initialState: getState<CacheType>("cache", CacheInitialState),
  reducers: {
    updateInvoiceTypes(state, { payload }: PayloadAction<tInvoiceTypes>) {
      state.invoiceTypes = payload;

      saveState("cache", current(state));
    },
    updateShippingTerms(state, { payload }: PayloadAction<tShippingTerms>) {
      state.shippingTerms = payload;

      saveState("cache", current(state));
    },
    updateExportReasons(state, { payload }: PayloadAction<tExportReasons>) {
      state.exportReasons = payload;

      saveState("cache", current(state));
    },
    updateCurrencies(state, { payload }: PayloadAction<tCurrencies>) {
      state.currencies = payload;

      saveState("cache", current(state));
    },
    updateCountries(
      state,
      {
        payload,
      }: PayloadAction<{
        countries: tCountries;
        countriesSelectData: SelectBoxDataType;
      }>
    ) {
      state.countries = payload.countries;
      state.countriesSelectData = payload.countriesSelectData;

      saveState("cache", current(state));
    },
    updateImportantCharges(
      state,
      { payload }: PayloadAction<{ key: "DLF" | "FRC"; data: tCharge }>
    ) {
      state.importantCharges[payload.key] = payload.data;

      saveState("cache", current(state));
    },
    updateOverview(state, { payload }: PayloadAction<tOverview>) {
      state.overview = payload;

      saveState("cache", current(state));
    },
    updateCarriers(state, { payload }: PayloadAction<tCarriers>) {
      state.carriers = payload;

      saveState("cache", current(state));
    },
    updateBranches(state, { payload }: PayloadAction<tBranches>) {
      state.branches = payload;

      saveState("cache", current(state));
    },
    updateBusinessSectors(state, { payload }: PayloadAction<tBusinessSectors>) {
      state.businessSectors = payload;

      saveState("cache", current(state));
    },
    updateTransportTypes(
      state,
      { payload }: PayloadAction<CacheType["transportTypes"]>
    ) {
      state.transportTypes = payload;

      saveState("cache", current(state));
    },
    clearcache(state) {
      for (const [k, v] of Object.entries(
        CacheInitialState
      ) as Entries<CacheType>) {
        state[k] = v as any; // Hmm
      }

      saveState("cache", current(state));
    },
  },
});

export const {
  updateInvoiceTypes,
  updateShippingTerms,
  updateExportReasons,
  updateCurrencies,
  clearcache,
  updateCountries,
  updateImportantCharges,
  updateOverview,
  updateBranches,
  updateCarriers,
  updateBusinessSectors,
  updateTransportTypes,
} = cacheSlice.actions;

export default cacheSlice.reducer;
