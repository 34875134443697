import { useState, ReactNode } from "react";

import { tRider } from "../../store/types/app.types";
import SelectRider, { tRoute } from "../../components/SelectRider/SelectRider";

type tTriggerFunction = React.Dispatch<
  React.SetStateAction<tRider | null>
> | null;

export type tSelectRiderOptions = { route?: tRoute; carrier?: string };

const useSelectRider = (
  options: tSelectRiderOptions = {}
): [ReactNode, (func: tTriggerFunction) => void] => {
  const [triggerFunction, setTriggerFunction] =
    useState<tTriggerFunction>(null);

  const defaultTriggerFunction = (rider: tRider) => null;

  const updateTriggerFunction = (func: tTriggerFunction) => {
    setTriggerFunction(() => func);
  };

  const closeHandler = () => {
    setTriggerFunction(null);
  };

  const render = (
    <SelectRider
      selectHandler={triggerFunction || defaultTriggerFunction}
      open={!!triggerFunction}
      closeHandler={closeHandler}
      route={options.route}
      carrier={options.carrier}
    />
  );

  return [render, updateTriggerFunction];
};

export default useSelectRider;
