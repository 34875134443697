import { useState, ReactNode } from "react";

import { tPackagingType } from "../../store/types/shipping.types";
import SelectPackagingType from "../../components/SelectPackagingType/SelectPackagingType";

type tTriggerFunction = React.Dispatch<
  React.SetStateAction<tPackagingType | null>
> | null;

const useSelectPackagingType = (): [
  ReactNode,
  (func: tTriggerFunction) => void
] => {
  const [triggerFunction, setTriggerFunction] =
    useState<tTriggerFunction>(null);

  const defaultTriggerFunction = (packagingTupe: tPackagingType) => null;

  const updateTriggerFunction = (func: tTriggerFunction) => {
    setTriggerFunction(() => func);
  };

  const closeHandler = () => {
    setTriggerFunction(null);
  };

  const render = (
    <SelectPackagingType
      selectHandler={triggerFunction || defaultTriggerFunction}
      open={!!triggerFunction}
      closeHandler={closeHandler}
    />
  );

  return [render, updateTriggerFunction];
};

export default useSelectPackagingType;
