import { useState } from "react";

import { IonIcon } from "@ionic/react";
import { searchOutline } from "ionicons/icons";

export type SelectBoxDataType = {
  key: string;
  value: { img?: string; text: string };
}[];

type SelectBoxType = {
  title: string;
  data: SelectBoxDataType;
  selected: string;
  handler: (value: string) => void;
  closeHandler: () => void;
};

const SelectBox = ({
  title,
  data,
  selected,
  handler,
  closeHandler,
}: SelectBoxType) => {
  const [search, setSearch] = useState<string>("");

  data = data.filter((dt) => new RegExp(search, "i").test(dt.value.text));

  return (
    <div className="select-component">
      <div className="select-component__main">
        <div className="select-component__header">
          <div></div>
        </div>
        <h3 className="select-component__heading">{title}</h3>
        <div className="select-component__search">
          <span>
            <IonIcon icon={searchOutline} />
          </span>
          <input
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="select-component__options">
          {data.map((dt) => {
            return (
              <div
                className="select-component__option"
                key={dt.key}
                onClick={(e) => {
                  handler(dt.key);
                  e.stopPropagation();
                }}
              >
                {/* {dt.value.img ? (
                  <img
                    src={dt.value.img}
                    alt=""
                    className="select-component__img"
                  />
                ) : null} */}
                <p>{dt.value.text}</p>
                <input
                  type="radio"
                  className="select-component__radio"
                  disabled
                  checked={selected === dt.key}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SelectBox;
