import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tInvoice } from "../../store/types/shipping.types";

import withAuth from "../../hoc/withAuth/withAuth";

import Header from "../../components/Header/Header";
import VerticalBarLoader from "../../Loaders/VerticalBarLoader/VerticalBarLoader";

import { getDate, roundDP } from "../../utils/func";

const Invoice = () => {
  const { id: invoiceId } = useParams();

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );

  const [invoice, setInvoice] = useState<tInvoice | null>(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api_client({
      url: `/invoice/${invoiceId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        setInvoice(res.data.data);
      })
      .catch((err) => {
        // navigate to error page
      })
      .finally(() => {
        setLoading(false);
      });
  }, [invoiceId, accessToken]);

  return (
    <>
      <Header />
      <main className="main">
        <div className="container">
          {loading ? <VerticalBarLoader /> : null}
          {!loading && invoice ? (
            <>
              <div className="invoice-header">
                <h1>Invoice</h1>
              </div>
              <div className="invoice">
                <div className="invoice__header">
                  <div>
                    <p className="font-bold">DMX</p>
                    <p>
                      {invoice.Branch.Address}, {invoice.Branch.State},{" "}
                      {invoice.Branch.Country.Name}
                    </p>
                    <p>
                      <a href="mailto: info@dmxlogistics.ng">
                        info@dmxlogistics.ng
                      </a>
                    </p>
                  </div>
                  <div>
                    <p>Invoice #{invoice._id}</p>
                    <p>Date: {getDate(new Date().toString())}</p>
                  </div>
                </div>
                <div className="invoice__ref">
                  <div>
                    <span className="font-bold">TO:</span>
                  </div>
                  <div>
                    <p>{invoice.Shipment.Cosignee.Name}</p>
                    <p>{invoice.Shipment.Cosignee.Company}</p>
                    <br />
                    <p>{invoice.Shipment.Cosignee.Address}</p>
                    <p>
                      {invoice.Shipment.Cosignee.City},{" "}
                      {invoice.Shipment.Cosignee.State}
                    </p>
                    <p>{invoice.Shipment.Cosignee.Country.Name}</p>
                    <p>2347046547575</p>
                  </div>
                </div>
                <div className="table-responsive table-responsive--1 mt-medium">
                  <table className="table table--bordered table--primary">
                    <thead>
                      <tr>
                        <th className="text-center">Description</th>
                        <th className="text-center">Amount Billed</th>
                        <th className="text-center">Conversion Rate</th>
                        <th className="text-center">Amount Invoiced</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{invoice.ShipmentCharge.Charge.Name}</td>
                        <td className="text-right">
                          NGN {roundDP(invoice.ShipmentCharge.Amount, 2)}
                        </td>
                        <td className="text-right">1.00</td>
                        <td className="text-right">
                          NGN {roundDP(invoice.ShipmentCharge.Amount, 2)}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}></td>
                        <td className="text-right">
                          <span className="font-bold">Sub Total</span>
                        </td>
                        <td className="text-right">
                          <span className="font-bold">
                            NGN {roundDP(invoice.ShipmentCharge.Amount, 2)}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}></td>
                        <td className="text-right">
                          <span className="font-bold">VAT</span>
                        </td>
                        <td className="text-right">-</td>
                      </tr>
                      <tr>
                        <td colSpan={2}></td>
                        <td className="text-right">
                          <span className="font-bold">WHT</span>
                        </td>
                        <td className="text-right">-</td>
                      </tr>
                      <tr>
                        <td colSpan={2}></td>
                        <td className="text-right">
                          <span className="font-bold">Discount</span>
                        </td>
                        <td className="text-right">-</td>
                      </tr>
                      <tr>
                        <td colSpan={2}></td>
                        <td className="text-right">
                          <span className="font-bold">Total Due</span>
                        </td>
                        <td className="text-right">
                          <span className="font-bold">
                            NGN {roundDP(invoice.ShipmentCharge.Amount, 2)}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="text-center mt-medium">
                  <span className="invoice__text">
                    Thank you for your patronage
                  </span>
                </div>
                <div className="invoice__line mt-medium"></div>
              </div>
              <div className="invoice-footer">
                <button className="btn btn--outline">All Invoices</button>
                <button className="btn btn--primary">Print</button>
              </div>
            </>
          ) : null}
        </div>
      </main>
    </>
  );
};

export default withAuth(Invoice);
