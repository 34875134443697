import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";

import { getState, saveState } from "./global";

import { tAdmin } from "./types/app.types";

type UserType = {
  loggedIn: boolean;
  admin: tAdmin | null;
  accessToken: string | null;
};

const UserInitialState: UserType = {
  loggedIn: false,
  admin: null,
  accessToken: null,
};

const userSlice = createSlice({
  name: "user",
  initialState: getState<UserType>("user", UserInitialState),
  reducers: {
    login(
      state,
      {
        payload,
      }: PayloadAction<{
        admin: UserType["admin"];
        accessToken: string;
      }>
    ) {
      state.loggedIn = true;
      state.accessToken = payload.accessToken;
      state.admin = payload.admin;

      saveState<UserType>("user", current(state));
    },
    clearUser(state) {
      state.loggedIn = false;
      state.admin = null;
      state.accessToken = null;

      saveState<UserType>("user", current(state));
    },
  },
});

export const { login, clearUser } = userSlice.actions;

export default userSlice.reducer;
