import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import api_client from "../../api/client";

import { tRootState } from "../../store";
import { tShipmentImport, tShipments } from "../../store/types/shipping.types";

import withAuth from "../../hoc/withAuth/withAuth";

import Header from "../../components/Header/Header";
import ShipmentActions from "../../components/ShipmentActions/ShipmentActions";
import VerticalBarLoader from "../../Loaders/VerticalBarLoader/VerticalBarLoader";

import { copyData, getDate, roundDP } from "../../utils/func";

const SingleShipmentsImport = () => {
  const { id: shipmentImportId } = useParams();

  const accessToken = useSelector(
    (state: tRootState) => state.user.accessToken
  );

  const [loading, setLoading] = useState(true);

  const [shipmentImport, setShipmentImport] = useState<tShipmentImport | null>(
    null
  );
  const [shipments, setShipments] = useState<tShipments>([]);

  useEffect(() => {
    api_client({
      method: "GET",
      url: `/shipments/import/${shipmentImportId}`,
      headers: { Authorization: `Bearer ${accessToken}` },
    })
      .then((res) => {
        const shpImport: tShipmentImport = res.data.data;

        setShipmentImport(shpImport);
        setShipments(shpImport.Shipments);
      })
      .catch((err) => {
        // navigate to error page
      })
      .finally(() => {
        setLoading(false);
      });
  }, [accessToken, shipmentImportId]);

  return (
    <>
      <Header />
      <main className="main">
        <div className="container">
          {loading ? <VerticalBarLoader /> : null}
          {!loading && shipmentImport ? (
            <>
              <div className="shipments-import-info__header">
                <h3>Shipment Imports</h3>
                <p>Date: {getDate(shipmentImport.DateAdded)}</p>
                <div className="shipments-import-info__metadata">
                  <p>
                    {shipmentImport.TotalRecords} TOTAL RECORDS
                    <a
                      href={`${shipmentImport.UploadedRecordsPath}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="fas fa-download"></span> Download
                    </a>
                  </p>
                  |<p>{shipmentImport.NumSuccessful} SUCCESSFUL</p>|
                  <p>
                    {shipmentImport.NumFailed} FAILED
                    {shipmentImport.FailedRecordsPath ? (
                      <a
                        href={`${shipmentImport.FailedRecordsPath}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="fas fa-download"></span> Download
                      </a>
                    ) : null}
                  </p>
                </div>
              </div>
              <div className="shipments-import-info">
                <div className="shipments-import-info__aside">
                  <div className="info-block">
                    <div className="info-block__info">
                      <p className="info-block__info-key">Shipment Type</p>
                      <div className="info-block__info-main">
                        {shipmentImport.ShipmentType}
                      </div>
                    </div>
                    <div className="info-block__info">
                      <p className="info-block__info-key">Branch</p>
                      <div className="info-block__info-main">
                        {shipmentImport.Branch.Name} (
                        {shipmentImport.Branch.State},{" "}
                        {shipmentImport.Branch.Country.Name})
                      </div>
                    </div>
                    <div className="info-block__info">
                      <p className="info-block__info-key">Pickup Date</p>
                      <div className="info-block__info-main">
                        {getDate(shipmentImport.PickupDate)}
                      </div>
                    </div>
                    <div className="info-block__info">
                      <p className="info-block__info-key">Pickup Info</p>
                      <div className="info-block__info-main">
                        <p>
                          <strong>Name:</strong>{" "}
                          {shipmentImport.PickupContact.Name}
                        </p>
                        <p>
                          <strong>Company:</strong>{" "}
                          {shipmentImport.PickupContact.Company}
                        </p>
                        <p>
                          <strong>Address:</strong>{" "}
                          {shipmentImport.PickupContact.State},{" "}
                          {shipmentImport.PickupContact.Country.Name}
                        </p>
                      </div>
                    </div>
                    <div className="info-block__info">
                      <p className="info-block__info-key">Delivery Date</p>
                      <div className="info-block__info-main">
                        {getDate(shipmentImport.DeliveryDate)}
                      </div>
                    </div>
                    <div className="info-block__info">
                      <p className="info-block__info-key">Carrier</p>
                      <div className="info-block__info-main">
                        {shipmentImport.Carrier.Name}
                      </div>
                    </div>
                    <div className="info-block__info">
                      <p className="info-block__info-key">Customer</p>
                      <div className="info-block__info-main">
                        {shipmentImport.User.Name} (
                        {shipmentImport.User.EmailAddress})
                      </div>
                    </div>
                    <div className="info-block__info">
                      <p className="info-block__info-key">Imported By</p>
                      <div className="info-block__info-main">
                        {shipmentImport.Admin.Name} (
                        {shipmentImport.Admin.EmailAddress})
                      </div>
                    </div>
                  </div>
                </div>
                <div className="shipments-import-info__main">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>ID</th>
                          {/* <th>Shipper Ref.</th> */}
                          <th>Item</th>
                          <th>Delivery Info</th>
                          <th>Carrier</th>
                          <th>Amount (&#8358;)</th>
                          <th>Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {shipments.map((shipment) => (
                          <tr key={shipment._id}>
                            <td>
                              <span onClick={() => copyData(shipment._id)}>
                                #{shipment._id.slice(shipment._id.length - 6)}
                              </span>
                            </td>
                            {/* <td>{shipment.ShipperReference}</td> */}
                            <td>{shipment.Description}</td>
                            <td>
                              <div>
                                <p>
                                  <em>Name:</em> {shipment.Cosignee.Name}
                                </p>
                                <p>
                                  <em>Address:</em> {shipment.Cosignee.State},{" "}
                                  {shipment.Cosignee.Country.Name}
                                </p>
                              </div>
                            </td>
                            <td>
                              {shipment.Carrier.Name}
                              {shipment.Rider ? (
                                <>
                                  <br />
                                  (Rider: {shipment.Rider.FirstName}{" "}
                                  {shipment.Rider.LastName})
                                </>
                              ) : null}
                            </td>
                            <td>{roundDP(shipment.TotalIncomeCharge, 2)}</td>
                            <td>
                              {shipment.Status}
                              <br />({shipment.LastActivity})
                            </td>
                            <td>
                              <ShipmentActions
                                setShipments={setShipments}
                                shipment={shipment}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          <div className="bottom-menu-clearfix"></div>
        </div>
      </main>
    </>
  );
};

export default withAuth(SingleShipmentsImport);
